// Super Admin
export const CREATE_PARTNERS_URL =
  "logistics/super-admin/register-all-logistics";
export const GET_PARTNERS_URL = "logistics/super-admin/delivery-partner";
export const UPDATE_PAYMENTS_URL = "logistics/super-admin/delivery-partner/pay";
export const DELETE_PARTNER_URL = "logistics/super-admin/logistics-company/";
export const PAYMENT_HISTORY_URL = "logistics/super-admin/transactions";

// Cokitchens
export const GET_COKITCHENS_URL = "internal/cokitchen";

// Logistics
export const GET_LOGISTICS_URL = "logistics";
export const CANCEL_ORDER_URL = "logistics/super-admin/order/cancel";
// Admin Home

export const GET_ORDERS_URL = "admin/orders";
export const GET_ORDERS_BY_BRAND_URL = "admin/orders/by/brand";
export const GET_ORDERS_BY_MEAL_URL = "admin/orders/by/meal";
// Users
export const GET_USERS_URL = "marketing/user";
