import React, { useState } from "react";
import Header from "../header";
import Tabs from "../tabs";
import Select from "../select";
import { IconButton } from "../buttons";
import { BiRefresh } from "react-icons/bi";
import { Link } from "react-router-dom";
import { PAYMENT_HISTORY } from "../../router/router";
import "./styles.scss";
import SideNav from "../sidenav";
const Layout = ({
  children,
  home,
  details,
  notifications,
  locationChange,
  withHistoryBtn,
  onHistoryClick,
  title,
  refreshLoading,
  onRefresh,
  location_set,
  paymentClass,
  options,
  serachBar,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <main className="main_container bg-dark-alt">
      <div className="main_container_content bg-dark-alt d-justify-end  ">
        <Header
          title="Logistics Super Admin"
          navClick={() => setOpen((prev) => !prev)}
          closed={open ? "ham_crossed" : ""}
        />
        {/* <Tabs
          home={home}
          details={details}
          notifications={notifications}
          location_set={location_set}
        /> */}
        <SideNav closed={open ? "" : "active"} />

        <div className={`mt-5 pt-2 ${open ? "content-min" : "content-max"}`}>
          <div className="flex_row_top content_section pb-0">
            <div className="width_52 pr_25 flex_column">
              <div className="  flex_row_top_center mb_25">
                <div>{title}</div>
                <Select
                  placeholder="Locations: ALL"
                  defaultValue={{ label: "Locations: ALL", value: "all" }}
                  handleChange={(e) => locationChange(e)}
                  options={options}
                />
              </div>

              <div className="width_full">{serachBar}</div>
            </div>
            <div className="second_width_desktop flex_row_right">
              <IconButton
                withIcon
                icon={<BiRefresh width="13px" height="13px" fill="#000000" />}
                text={refreshLoading ? "Refreshing..." : "Refresh"}
                className=""
                isBlack
                loading={refreshLoading}
                onClick={onRefresh}
                rotate
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    </main>
  );
};
export default Layout;
