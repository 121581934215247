import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import {
  GET_ORDERS_URL,
  CANCEL_ORDER_URL,
  PAYMENT_HISTORY_URL,
  GET_LOGISTICS_URL,
  GET_USERS_URL,
  GET_ORDERS_BY_BRAND_URL,
  GET_ORDERS_BY_MEAL_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import { LIVE_TRIPS } from "../../router/router";
import moment from "moment";

export class HomeStore {
  @observable orders = {};
  @observable logistics = {};
  @observable ongoingTrips = [];
  @observable users = [];
  @observable dataCached = false;
  @observable dataUsersCached = false;
  @observable dataFBCached = false;
  @observable dataBrandsCached = false;
  @observable dataMealsCached = false;
  @observable ordersDateRange = "";
  @observable usersDateRange = "";
  @observable ordersByBrand = [];
  @observable ordersByMeal = [];

  @observable dateRange = 0;
  @observable dateRangeReg = 0;
  @action updateOngoingTrips = (item) => {
    this.ongoingTrips = item;

    this.dataFBCached = true;
  };

  getAdminOrders = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getAuthData(GET_ORDERS_URL);

      runInAction(() => {
        let res = response?.data?.data;
        let resCopy = [];
        for (let index = 0; index < res.length; index++) {
          if (res[index].completed) {
            resCopy.push(res[index]);
          }
        }
        let moments = res?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        let maxDate = moment(moments[0]?.created_at).format("MM/DD/YYYY");
        let minDate = moment(moments[moments?.length - 1]?.created_at).format(
          "MM/DD/YYYY"
        );

        let diff = moment(new Date(maxDate)).diff(
          moment(new Date(minDate)),
          "days"
        );
        this.dateRange = diff;
        this.ordersDateRange = {
          startDate: new Date(minDate),
          endDate: new Date(maxDate),
          key: "selection",
        };
        this.dataCached = true;
        this.orders = resCopy;
        console.log("Res", new Date(minDate), minDate);
        shouldAlert &&
          alert.show("Data loaded successfully!", {
            type: "success",
          });
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  getLogistics = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getAuthData(GET_LOGISTICS_URL);

      runInAction(() => {
        let res = response.data && response.data;
        let moments = res && res.trips.map((x) => moment(x.created_at));
        let maxDate = moment.max(moments).format("D MMM YY");
        let minDate = moment.min(moments).format("D MMM YY");
        res.max_date = maxDate;
        res.min_date = minDate;
        this.dataCached = true;
        this.logistics = res;
        shouldAlert &&
          alert.show("Logistics data loaded successfully!", {
            type: "success",
          });
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  cancelOrders = async (alert, data, history) => {
    try {
      let response = await dataService.postAuthData(CANCEL_ORDER_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;
        console.log(res);
        if (res) {
          alert.show("Order cancelled successfully!", {
            type: "success",
          });

          this.dataCached = true;

          history.push(LIVE_TRIPS);
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
      const errMsg =
        error && (error.response || error.message || error.toString() || error);
      let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
      console.log("Errror Message", errorMsg);
    }
  };
  getUsers = async (alert, show) => {
    try {
      let response = await dataService.getAuthData(GET_USERS_URL);

      runInAction(() => {
        let res = response.data && response.data.data;
        let moments = res?.map((x) => moment(x.created_at));
        let maxDate = moment.max(moments).format("MM/DD/YYYY");
        let minDate = moment.min(moments).format("MM/DD/YYYY");

        let diff = moment(new Date(maxDate)).diff(
          moment(new Date(minDate)),
          "days"
        );
        this.dateRangeReg = diff;
        this.usersDateRange = {
          startDate: new Date(minDate),
          endDate: new Date(maxDate),
          key: "selection",
        };
        this.users = res;
        show &&
          alert.show("users loaded successfully!", {
            type: "success",
          });

        this.dataUsersCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getOrdersByBrand = async (alert) => {
    try {
      let response = await dataService.getAuthData(GET_ORDERS_BY_BRAND_URL);

      runInAction(() => {
        let res = response?.data?.data;
        this.ordersByBrand = res;
        this.dataBrandsCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getOrdersByMeals = async (alert) => {
    try {
      let response = await dataService.getAuthData(GET_ORDERS_BY_MEAL_URL);

      runInAction(() => {
        let res = response?.data?.data;
        this.ordersByMeal = res;
        this.dataMealsCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new HomeStore();
