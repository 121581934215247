import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import TextCard from "../../components/textCard";

import { ChevronRight, User } from "../../assets/icons/icons";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import Live from "../../components/live";
import "./styles.scss";
import Caps from "../../components/tabs/capTabs";
import { IoMdWallet } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { FULL_TRIP_DETAILS } from "../../router/router";
import { withAlert } from "react-alert";
import homeStore from "../../stores/superAdminStores/homeStore";
import CommonStore from "../../stores/superAdminStores/commonStore";
import Loader from "react-loader-spinner";
import moment from "moment";
import RateCard from "../../components/rateCard";
import IconInput from "../../components/inputs/iconInput";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiTwotonePhone } from "react-icons/ai";
import { AiTwotoneMail } from "react-icons/ai";
import Select from "../../components/select";
const UsersPage = ({ alert }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [showDateRange, setShowDateRange] = useState(true);
  const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);

  const [cokitchens, setCokitchens] = useState([]);
  const [dateRanges, setDateRanges] = useState({
    dateRange: homeStore.dateRange + 1,
  });
  const [logistics, setLogistics] = useState(null);
  const [logisticsCopy, setLogisticsCopy] = useState(null);
  const [users, setUsers] = useState([]);
  const [usersCopy, setUsersCopy] = useState([]);
  const [bikesOnline, setBikesOnline] = useState([]);
  const [bikesOffline, setBikesOffline] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);

  const [todayTrips, setTodayTrips] = useState([]);
  const [yesterdayTrips, setYesterdayTrips] = useState([]);
  const [showTodayTrips, setShowTodayTrips] = useState(true);
  const [showYesterdayTrips, setShowYesterdayTrips] = useState(false);
  const [showAllTrips, setShowAllTrips] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [location, setLocation] = useState("");
  const [usersData, setUsersGraphData] = useState([]);
  const [sortVal, setSortVal] = useState({
    label: "Sort By: Name",
    value: "name",
  });
  const [refreshCount, setRefreshCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [allTimeRevenue, setAllTimeRevenue] = useState(0);
  const [revenueStats, setRevenueStats] = useState({
    revenue: 0,
    averageOrderValue: 0,
    highestOrderAmount: 0,
    lowestOrderAmount: 0,
  });

  const [statsValues, setStatsValues] = useState({
    males: 0,
    females: 0,
    active: 0,
    inactive: 0,
  });

  const [usersByPurchase, setUsersByPurchase] = useState([]);
  const [usersByPurchaseAll, setUsersByPurchaseAll] = useState([]);
  const [usersByOrders, setUsersByOrders] = useState([]);
  const [usersByOrdersAll, setUsersByOrdersAll] = useState([]);
  const [cashOrders, setCashOrders] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphDataAlt, setGraphDataAlt] = useState([]);
  const [graphDataStats, setGraphDataStats] = useState({ min: 0, max: 0 });
  const [cardOrders, setCardOrders] = useState([]);
  const [pickupOrders, setPickupOrders] = useState([]);
  const [deliveryOrders, setDeliveryOrders] = useState([]);

  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
  // const valid = (current) => {
  //   return current.isAfter(moment(startDate));
  // };

  // load data from store
  useEffect(async () => {
    await loadData();

    setLoading(false);

    setLogisticsCopy(homeStore.orders);
    setUsersCopy(homeStore.users);
    setUsers(homeStore.users);
    await sortTrips();
    await extractUsersFromOrders(homeStore.orders);
    await sortUsersByDates(homeStore.users);
    sortBikers();
    setCokitchens(CommonStore.cokitchens);
  }, []);
  // Trigger Refresh
  useEffect(async () => {
    if (refreshCount) {
      await loadData();
      setLogisticsCopy(homeStore.orders);
      setUsersCopy(homeStore.users);
      await sortTrips();
      await extractUsersFromOrders(homeStore.orders);
      await sortUsersByDates(homeStore.users);
    }
  }, [refreshCount]);

  // Filter stats by date
  useEffect(async () => {
    if (filterCount > 1) {
      let i, j;

      let usersCopy = homeStore?.users;

      let currentTrips = [];

      for (i = 0; i < usersCopy.length; i++) {
        if (
          moment(
            moment(usersCopy[i].created_at).format("YYYY-MM-DD")
          ).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ) {
          currentTrips.push(usersCopy[i]);
        }
      }
      await extractUsersFromOrders(homeStore.orders);
      setUsers(currentTrips);
      let minMax = getMinMax(currentTrips);
      const revTotal = getTotal(currentTrips) || 0;
      const avrgVal = revTotal / currentTrips?.length;
      setRevenue(getTotal(currentTrips));
      setRevenueStats({
        ...revenueStats,
        revenue: revTotal,
        averageOrderValue: avrgVal,
        highestOrderAmount: minMax[1],
        lowestOrderAmount: minMax[0],
      });

      sortUsersStats(currentTrips);
    } else {
      const revTotal = getTotal(todayTrips) || 0;
      const avrgVal = revTotal / todayTrips?.length;
      let minMax = getMinMax(todayTrips);
      setUsers(todayTrips);
      setRevenueStats({
        ...revenueStats,
        revenue: revTotal,
        averageOrderValue: avrgVal,
        highestOrderAmount: minMax[1],
        lowestOrderAmount: minMax[0],
      });

      sortUsersStats(todayTrips);

      setSelectionRange({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      });

      await extractUsersFromOrders(homeStore.orders);
    }
  }, [filterCount]);

  // load data from store
  const loadData = async () => {
    setLoading(true);
    setOrdersLoading(true);
    if (!homeStore.dataUsersCached || filterCount > 1) {
      await homeStore.getUsers(alert, true);
    }
    setUsers(homeStore.users);
    await sortUsersByDates(homeStore.users);
    await sortTrips();
    setLoading(false);
    if (!homeStore.dataCached || filterCount > 1) {
      await homeStore.getAdminOrders(alert, true, moment);
    }

    await extractUsersFromOrders(homeStore.orders);
    setLogisticsCopy(homeStore.orders);
    setUsersCopy(homeStore.users);
    setOrdersLoading(false);
    await CommonStore.getCokitchens(alert);
    setCokitchens(CommonStore.cokitchens);
    setLoading(false);
  };

  // Reducer function to compute total
  const getTotal = (items = []) => {
    let total =
      items && items[0]
        ? items.reduce(function (acc, curr) {
            return (
              acc +
              parseFloat(curr?.calculated_order?.total_amount) +
              parseFloat(curr?.change || 0)
            );
          }, 0)
        : 0;
    return total;
  };

  // Sort user stats
  const sortUsersStats = (items = []) => {
    let males = [];
    let females = [];

    let actives = [];
    let inactives = [];
    for (let i = 0; i < items.length; i++) {
      // Sort cash and card
      if (items[i]?.user_gender == "Male") {
        males.push(items[i]);
      } else if (items[i]?.user_gender == "Female") {
        females.push(items[i]);
      }
      // Sort pickup and delivery
      if (items[i]?.calculated_order?.pickup) {
        actives.push(items[i]);
      } else {
        inactives.push(items[i]);
      }
    }
    setStatsValues({
      males: males?.length,
      females: females.length,
      active: getTotal(actives),
      inactive: getTotal(inactives),
    });
  };
  // Sorts Users by purchase
  const sortUsersByPurcahse = (sortedUser = []) => {
    console.log("Sorted Users", sortedUser);
    let currentsPurchaseUsersArr = [];
    let currentPurchaseUsersCountArr = [];
    let currentPurchaseUsersCountArrAll = [];

    let currentsOrdersUsersArr = [];
    let currentOrdersUsersCountArr = [];
    let currentOrdersUsersCountArrAll = [];
    let brandsDataArr = [
      { color: "#E38627" },
      { color: "#DC143C" },
      { color: "#6A2135" },
      { color: "#e799a3" },
      { color: "#4cc552" },
    ];
    // Sort meals orders by date
    for (let i = 0, len = sortedUser.length; i < len; i++) {
      currentsPurchaseUsersArr.push({
        userOrders: getTotal(
          sortedUser[i]?.orders?.filter((item, j) =>
            moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
              moment(moment(selectionRange.startDate).subtract(1, "d")).format(
                "YYYY-MM-DD"
              ),
              moment(moment(selectionRange.endDate).add(1, "d")).format(
                "YYYY-MM-DD"
              )
            )
          )
        ),
        details: sortedUser[i],
      });

      currentsOrdersUsersArr.push({
        userOrders: sortedUser[i]?.orders?.filter((item, j) =>
          moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ),
        details: sortedUser[i],
        userOrdersAmount: getTotal(
          sortedUser[i]?.orders?.filter((item, j) =>
            moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
              moment(moment(selectionRange.startDate).subtract(1, "d")).format(
                "YYYY-MM-DD"
              ),
              moment(moment(selectionRange.endDate).add(1, "d")).format(
                "YYYY-MM-DD"
              )
            )
          )
        ),
      });
    }

    currentsOrdersUsersArr = currentsOrdersUsersArr.sort(
      (a, b) => b?.userOrders?.length - a?.userOrders?.length
    );
    currentsPurchaseUsersArr = currentsPurchaseUsersArr.sort(
      (a, b) => b?.userOrders - a?.userOrders
    );
    // Sort meals orders by date

    let ind = 0;

    while (ind < 5) {
      currentOrdersUsersCountArr.push({
        title: currentsOrdersUsersArr[ind]?.details?.user,
        value: currentsOrdersUsersArr[ind]?.userOrders?.length,
        color: brandsDataArr[ind].color,
      });
      currentPurchaseUsersCountArr.push({
        title: currentsPurchaseUsersArr[ind]?.details?.user,
        value: currentsPurchaseUsersArr[ind]?.userOrders,
        color: brandsDataArr[ind].color,
      });

      ind++;
    }

    for (let i = 0, len = currentsOrdersUsersArr.length; i < len; i++) {
      currentOrdersUsersCountArrAll.push({
        title: currentsOrdersUsersArr[i]?.details?.user,
        orders: currentsOrdersUsersArr[i]?.userOrders?.length,
        amount: currentsOrdersUsersArr[i]?.userOrdersAmount,
        details: currentsOrdersUsersArr[i]?.details,
      });
    }
    setUsersByOrders(
      currentOrdersUsersCountArr
        .sort((a, b) => b.value - a.value)
        .filter((item) => item.value !== undefined)
    );
    setUsersByPurchase(
      currentPurchaseUsersCountArr
        .sort((a, b) => b.value - a.value)
        .filter((item) => item.value !== undefined)
    );

    currentPurchaseUsersCountArrAll = JSON.parse(
      JSON.stringify(currentOrdersUsersCountArrAll)
    );

    setUsersByPurchaseAll(
      currentPurchaseUsersCountArrAll.sort((a, b) => b.amount - a.amount)
    );
    setUsersByOrdersAll(
      currentOrdersUsersCountArrAll.sort((a, b) => b.orders - a.orders)
    );
    console.log(
      "currentMealsOrdersArr",
      sortedUser,
      currentsOrdersUsersArr,
      currentOrdersUsersCountArr,
      "purchasefvgvg all",
      currentOrdersUsersCountArrAll
    );
  };

  // Get minimum and max revenues
  const getMinMax = (items = []) => {
    let min = parseFloat(
        items && items[0] && items[0].calculated_order?.total_amount
      ),
      max = parseFloat(
        items && items[0] && items[0].calculated_order?.total_amount
      );

    for (let i = 1; i < items.length; i++) {
      let value = parseFloat(items[i].calculated_order?.total_amount);
      min = value < min ? value : min;
      max = value > max ? value : max;
    }
    return [min, max];
  };

  // Get minimum and max revenues for the graph
  const getMinMaxAlt = (items = []) => {
    let min = items && items[0] && items[0],
      max = items && items[0] && items[0];
    let minObj, maxObj;

    for (let i = 1; i < items?.length; i++) {
      let value = items[i];
      min =
        parseFloat(value.y) < parseFloat(min?.y || min)
          ? parseFloat(value.y)
          : parseFloat(min?.y || min);
      max =
        parseFloat(value.y) > parseFloat(max?.y || max)
          ? parseFloat(value.y)
          : parseFloat(max?.y || max);
    }
    for (let i = 0; i < items.length; i++) {
      if (parseFloat(items[i].y) === min) {
        minObj = items[i];
      } else if (parseFloat(items[i].y) === max) {
        maxObj = items[i];
      }
    }

    setGraphDataStats({ ...graphDataStats, min: minObj, max: maxObj });
    console.log("Graph minmax", [minObj, maxObj], min, max);
    return [minObj, maxObj];
  };
  // Sort Revenue by date

  // Sort trips into today, yesterday and all time, cash, card
  const sortTrips = async () => {
    let todTrips = [];
    let yesTrips = [];

    let allTrips = homeStore?.users || [];

    for (let i = 0; i < allTrips.length; i++) {
      if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
        todTrips.push(allTrips[i]);
      } else if (
        moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
      ) {
        yesTrips.push(allTrips[i]);
      }
    }

    sortUsersStats(todTrips);

    // let minMax = await getMinMax(todTrips);

    // const revTotal = (await getTotal(todTrips)) || 0;
    // const allTotal = (await getTotal(allTrips || [])) || 0;
    // const avrgVal = revTotal / todTrips?.length;
    // setAllTimeRevenue(allTotal);
    // setRevenueStats({
    //   ...revenueStats,
    //   revenue: revTotal,
    //   averageOrderValue: avrgVal,
    //   highestOrderAmount: minMax[1],
    //   lowestOrderAmount: minMax[0],
    // });

    // setCashOrders(males);
    // setCardOrders(females);
    // setPickupOrders(actives);
    // setDeliveryOrders(inactives);

    // setRevenue(getTotal(todTrips));
    setUsers(todTrips);
    setTodayTrips(todTrips);
    setYesterdayTrips(yesTrips);
  };

  // Sort orders into a list of their common dates
  const sortUsersByDates = (items = []) => {
    let currentGraphTrips = [];
    let allOrdersAlt =
      items &&
      items[0] &&
      Array.from(
        items &&
          items.reduce(
            (acc, o) => (
              acc.get(moment(o.created_at).format("YYYY-MM-DD")).push(o), acc
            ),
            new Map(
              items &&
                items.map((o) => [
                  moment(o.created_at).format("YYYY-MM-DD"),
                  [],
                ])
            )
          ),
        ([key, value]) => {
          return {
            orders: value,

            x: moment(value[0].created_at).format("DD/MM/YY"),
            y: value?.length,
            date: value[0].created_at,
            userDetail: value[0]?.calculated_order?.user,
          };
        }
      );

    let currentGraphTripsAlt = JSON.parse(
      JSON.stringify(allOrdersAlt?.sort((a, b) => b.y - a.y))
    );
    setGraphData(allOrdersAlt);
    setGraphDataAlt(currentGraphTripsAlt);
    getMinMaxAlt(allOrdersAlt);
    return allOrdersAlt;
  };
  // Set current filter date
  const setFilterDate = () => {
    let currDate = "";
    if (
      moment(selectionRange?.startDate).format("DD-MM-YYYY") === today &&
      moment(selectionRange?.endDate).format("DD-MM-YYYY") === today
    ) {
      currDate = "Today";
    } else if (
      moment(selectionRange?.startDate).format("DD-MM-YYYY") === yesterday &&
      moment(selectionRange?.endDate).format("DD-MM-YYYY") === yesterday
    ) {
      currDate = "Yesterday";
    } else {
      currDate =
        moment(selectionRange?.startDate).format("D MMM 'YY") +
        " - " +
        moment(selectionRange?.endDate).format("D MMM 'YY");
    }

    return currDate;
  };

  // Extract users from orders
  const extractUsersFromOrders = async (items = []) => {
    items =
      items[0] &&
      items?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    let allOrdersAlt =
      items &&
      items[0] &&
      Array.from(
        items &&
          items.reduce(
            (acc, o) => (acc.get(o?.calculated_order?.user?.id).push(o), acc),
            new Map(
              items && items.map((o) => [o?.calculated_order?.user?.id, []])
            )
          ),
        ([key, value]) => {
          return {
            orders: value,
            ordersLength: value?.length,
            user:
              value[0]?.calculated_order?.user?.first_name +
              " " +
              value[0]?.calculated_order?.user?.last_name,
            date: value[0]?.created_at,
            total: getTotal(value),
            userDetail: value[0]?.calculated_order?.user,
          };
        }
      );

    console.log(
      "All orders by users",
      allOrdersAlt?.length,
      allOrdersAlt && allOrdersAlt
    );

    await setUsersGraphData(allOrdersAlt);
    await sortUsersByPurcahse(allOrdersAlt);
    return allOrdersAlt;
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setFilterCount((prev) => prev + 1);
  };

  const sortBikers = () => {
    let online = [];
    let offLine = [];
    let allRiders =
      (homeStore && homeStore.logistics && homeStore.logistics.fleets) || [];
    let i;
    for (i = 0; i < allRiders.length; i++) {
      if (allRiders[i].online) {
        online.push(allRiders[i]);
      } else {
        offLine.push(allRiders[i]);
      }
    }
    setBikesOnline(online);
    setBikesOffline(offLine);
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Layout
      locationChange={setLocation}
      home="active_nav_item"
      options={cokitchens}
      onRefresh={() => window.location.reload()}
      refreshLoading={refreshCount && loading}
    >
      {/* Top section start */}
      <section className="flex-column w-100 d-justify start d-align-start content_section main_section">
        <section className=" flex_row_top  tab_column mb-1 w-100">
          <section className="left_section pr_25 width_full">
            <section className="top_left grid_third mb_35">
              {/* <TextCard
              cardLabel="Revenue"
              cardValue={`₦${numberWithCommas(revenueStats?.revenue || 0)}`}
              cardFooter={setFilterDate()}
              loading={loading}
            /> */}

              <TextCard
                cardLabel="Users"
                cardValue={users?.length || "0"}
                withFooter
                cardFooter={setFilterDate()}
                loading={loading}
              />

              <TextCard
                cardLabel="Highest Registration Day"
                cardValue={`${graphDataAlt[0]?.x}`}
                link={<Live />}
                withFooter
                cardFooter={"All time"}
                loading={loading}
              />

              <TextCard
                cardLabel="Average Daily Registration"
                cardValue={`${numberWithCommas(
                  parseInt(usersCopy?.length / (homeStore.dateRangeReg + 1)) ||
                    0
                )}`}
                withFooter
                cardFooter={"All time"}
                loading={loading}
              />
            </section>

            {/* Left middle start */}

            <div className="d-justify-start d-align-start mb-4 mt-2 flex-wrap-alt">
              <RateCard
                type="pie"
                cardLabel="Top Users by Purchase"
                pieData={usersByPurchase}
                date={setFilterDate()}
                className="mr-0 mr-md-2 mb-md-0 mb-2"
                loading={ordersLoading}
                rateTwo="See full breakdown"
                footerClass="gray underline pointer"
                modalTitle={`Users by Purchase Full Breakdown:  ${setFilterDate()}`}
                modalBody={usersByPurchaseAll}
                modalType="purchase"
              />
              <RateCard
                type="pie"
                cardLabel="Top Users by Orders"
                pieData={usersByOrders}
                date={setFilterDate()}
                className=""
                loading={ordersLoading}
                rateTwo="See full breakdown"
                footerClass="gray underline pointer"
                modalTitle={` Users by Orders Full Breakdown:  ${setFilterDate()}`}
                modalBody={usersByOrdersAll}
                modalType="orders"
              />
            </div>

            {/* Left middle end */}

            {/* Left bottom section start*/}
          </section>
          {/* Right section start */}
          <section className="right_section px_10 second_width_desktop">
            <div className="flex_row_right mb_25">
              <a
                href="#/"
                onClick={() => {
                  setUsers(homeStore?.users);
                  setRevenue(getTotal(homeStore?.orders));
                  setFilterCount((prev) => prev + 1);
                  setSelectionRange(homeStore?.usersDateRange);
                }}
              >
                <label className="gray_label text-white bold cursor_pointer">
                  Show all time data
                </label>
              </a>
            </div>

            <div
              className="button red_border borderless_dates flex_row_center mb_30"
              onClick={() => {
                if (showDateRange) {
                  return;
                } else {
                  setShowDateRange(true);
                }
              }}
            >
              {showDateRange && (
                <div
                  className={showDateRangeFilters ? "" : "collapsed_date_range"}
                >
                  <div className="flex_row_between">
                    <button
                      className="cursor_pointer mb_12 date_filter_button"
                      onClick={() =>
                        setShowDateRangeFilters(!showDateRangeFilters)
                      }
                    >
                      {showDateRangeFilters
                        ? "Hide date filters"
                        : " Show date filters"}
                    </button>
                    {filterCount > 1 ? (
                      <button
                        className="cursor_pointer mb_12 date_filter_button"
                        onClick={() => setFilterCount(0)}
                      >
                        Clear date filter
                      </button>
                    ) : null}
                  </div>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>

            {/* Summary item */}
            <div className="flex_column"></div>
          </section>

          {/* Right section end */}
        </section>
        {/* Top setion end */}

        <div className="d-justify-start d-align-start mb-4 flex-wrap-alt w-100">
          <RateCard
            type="progressBar"
            loading={loading}
            cardLabel="Male vs Female"
            className="mr-0 mr-md-2 mb-md-0 mb-2"
            date={setFilterDate()}
            rateOne={`Males (${
              parseFloat(
                (statsValues?.males /
                  (statsValues?.males + statsValues?.females)) *
                  100
              )
                ? parseFloat(
                    (statsValues?.males /
                      (statsValues?.males + statsValues?.females)) *
                      100
                  ).toFixed(1)
                : 0
            }%): ${numberWithCommas(statsValues?.males || 0)}`}
            rateTwo={`Females (${
              parseFloat(
                (statsValues?.females /
                  (statsValues?.males + statsValues?.females)) *
                  100
              )
                ? parseFloat(
                    (statsValues?.females /
                      (statsValues?.males + statsValues?.females)) *
                      100
                  ).toFixed(1)
                : 0
            }%): ${numberWithCommas(statsValues?.females || 0)}`}
            value={statsValues?.males || 0}
            maxValue={statsValues?.males + statsValues?.females}
          />

          {/* <RateCard
            type="progressBar"
            loading={ordersLoading}
            cardLabel="Active Registered Users vs Registered New Users"
            className="mr-0 mr-md-2 mb-md-0 mb-2"
            date={setFilterDate()}
            rateOne={`Active (${
              parseInt((usersData?.length / usersCopy?.length) * 100)
                ? parseInt((usersData?.length / usersCopy?.length) * 100)
                : 0
            }%): ${numberWithCommas(usersData?.length || 0)}`}
            rateTwo={`Inactive (${
              parseInt(
                ((usersCopy?.length - usersData?.length) / usersCopy?.length) *
                  100
              )
                ? parseInt(
                    ((usersCopy?.length - usersData?.length) /
                      usersCopy?.length) *
                      100
                  )
                : 0
            }%): ${numberWithCommas(
              usersCopy?.length - usersData?.length || 0
            )}`}
            value={usersData?.length || 0}
            maxValue={usersCopy?.length || 0}
          /> */}

          <RateCard
            type="progressBar"
            loading={ordersLoading}
            cardLabel="Active Users vs Inactive Users"
            date={"All-time"}
            rateOne={`Active (${
              parseInt((usersData?.length / usersCopy?.length) * 100)
                ? parseInt((usersData?.length / usersCopy?.length) * 100)
                : 0
            }%): ${numberWithCommas(usersData?.length || 0)}`}
            rateTwo={`Inactive (${
              parseInt(
                ((usersCopy?.length - usersData?.length) / usersCopy?.length) *
                  100
              )
                ? parseInt(
                    ((usersCopy?.length - usersData?.length) /
                      usersCopy?.length) *
                      100
                  )
                : 0
            }%): ${numberWithCommas(
              usersCopy?.length - usersData?.length || 0
            )}`}
            value={usersData?.length || 0}
            maxValue={usersCopy?.length || 0}
          />
        </div>

        <div className="mb-4 w-100">
          <RateCard
            data={graphData.sort((a, b) => new Date(a.date) - new Date(b.date))}
            type="graph"
            loading={false}
            cardLabel="Users Registration Rate Chart"
            align="start"
            date={"All-time"}
            rateTwo={`All-time Low (${
              graphDataStats?.min?.x
            }): ${numberWithCommas(graphDataStats?.min?.y || 0)}`}
            rateOne={`All-time High (${
              graphDataStats?.max?.x
            }): ${numberWithCommas(graphDataStats?.max?.y || 0)}`}
            value={usersData?.length}
            maxValue={usersData?.length + usersCopy?.length}
          />
        </div>
        {/* Users table start */}
        <section className="bottom_left_section bg-white w-100 position-relative">
          <div className="border_bottom_thin flex_row_between pb-3 w-100">
            <h5 className="mb_15">All Users ({homeStore?.users?.length})</h5>
            {/* <Link
              to="#/"
              onClick={() => {
                if (showAll) {
                  setShowAll(false);
                  setShowTodayTrips(false);
                  setShowYesterdayTrips(false);
                  setShowAllTrips(false);
                } else {
                  setShowAll(true);
                  setShowTodayTrips(true);
                  setShowYesterdayTrips(true);
                  setShowAllTrips(true);
                }
              }}
            >
              <p className="gray_label mb_15 bold">
                {showAll ||
                (showAllTrips && showTodayTrips && showYesterdayTrips)
                  ? "Collapse all"
                  : "Show all"}
              </p>
            </Link> */}
            <Select
              containerClass="w-30"
              placeholder="Locations: ALL"
              defaultValue={{ label: "Sort By: Name", value: "name" }}
              handleChange={(e) => setSortVal(e)}
              options={[
                { label: "Sort By: Name", value: "name" },
                { label: "Sort By: Wallet Balance", value: "balance" },
                { label: "Sort By: Registration Date", value: "reg" },
              ]}
            />
            <IconInput
              containerClass="w-30"
              placeholder="Search user by name, gender, role"
              withIcon
              icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
              value={searchValue}
              onChange={(e) => setSearchValue(e)}
            />
          </div>

          <div className="overflow-scroll w-100">
            <div className="border_bottom_thin flex_row_between py_15">
              <p className="bold_18_px">Today ({todayTrips.length})</p>
              <Link to="#/" onClick={() => setShowTodayTrips((prev) => !prev)}>
                <p className="gray_label mb_15 bold">
                  {showTodayTrips ? "Collapse" : "Display"}
                </p>
              </Link>
            </div>

            <table className="width_full">
              <thead>
                <tr>
                  <th className="pl-0">Name</th>
                  <th className="pl-0">Date Registered</th>{" "}
                  <th className="pl-0">Gender/Role</th>
                  <th className="pl-0">Contact</th>
                  <th className="px-0">Wallet Balance</th>
                </tr>
              </thead>
              <tbody>
                <p className="gray_label bold_21px uppercase mt_25 pb_15">
                  {!loading && todayTrips.length === 0
                    ? "No user has registered today"
                    : null}
                </p>
                {/* Loader */}
                {loading ? (
                  <tr>
                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_center">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_right">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_right">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
                {/* Loader End */}
                {showTodayTrips
                  ? todayTrips

                      .sort((a, b) => {
                        if (sortVal?.value === "name") {
                          return a?.first_name?.localeCompare(b?.first_name);
                        } else if (sortVal?.value === "balance") {
                          return parseFloat(b.balance) - parseFloat(a.balance);
                        } else if (sortVal?.value === "reg") {
                          return (
                            new Date(b.created_at) - new Date(a.created_at)
                          );
                        }
                      })
                      .filter(
                        (item) =>
                          (item?.first_name + " " + item?.last_name)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (item?.user_gender)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (item?.role)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase())
                      )
                      .map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i + "item"}>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 capitalize">
                                  {item?.first_name + " " + item?.last_name}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 mb_4">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format("LT")}
                                </label>
                                <label className="text-dark-50">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <label className="text-dark-50 mb_4">
                                    {item?.user_gender}
                                  </label>
                                </div>

                                <div className="flex_row_align_center">
                                  <label className="text-dark-50 mb_4">
                                    {item?.role}
                                  </label>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb_17">
                                  <AiTwotonePhone
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <label className="text-dark-50 mb_4">
                                    {item?.phone_number}
                                  </label>
                                </div>

                                <div className="flex_row_align_center">
                                  <AiTwotoneMail
                                    fill="#939393"
                                    width="9px"
                                    height="9px"
                                    className="mr_15"
                                  />

                                  <label className="text-dark-50">
                                    {item?.email}
                                  </label>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.balance &&
                                    numberWithCommas(parseInt(item?.balance))}
                                </span>

                                <div className="flex_row_right">
                                  <Link to={`#/`}>
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      View & Edit
                                    </p>
                                  </Link>
                                  <Link to={`#/`}>
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </table>

            <div className="border_bottom_thin flex_row_between py_15">
              <p className="bold_18_px">Yesterday ({yesterdayTrips.length})</p>

              <Link
                to="#/"
                onClick={() => setShowYesterdayTrips((prev) => !prev)}
              >
                <p className="gray_label mb_15 bold">
                  {showYesterdayTrips ? "Collapse" : "Display"}
                </p>
              </Link>
            </div>
            <table className="width_full">
              {showYesterdayTrips ? (
                <thead>
                  <tr>
                    <th className="pl-0">Name</th>
                    <th className="pl-0">Date Registered</th>{" "}
                    <th className="pl-0">Gender/Role</th>
                    <th className="pl-0">Contact</th>
                    <th className="px-0">Wallet Balance</th>
                  </tr>
                </thead>
              ) : null}
              <tbody>
                <p className="gray_label bold_21px uppercase mt_25 pb_15">
                  {!loading && yesterdayTrips.length === 0
                    ? "No user registered yesterday"
                    : null}
                </p>
                {/* Loader */}
                {loading ? (
                  <tr>
                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_center">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex_column_right">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
                {/* Loader End */}
                {showYesterdayTrips
                  ? yesterdayTrips

                      .sort((a, b) => {
                        if (sortVal?.value === "name") {
                          return a?.first_name?.localeCompare(b?.first_name);
                        } else if (sortVal?.value === "balance") {
                          return parseFloat(b.balance) - parseFloat(a.balance);
                        } else if (sortVal?.value === "reg") {
                          return (
                            new Date(b.created_at) - new Date(a.created_at)
                          );
                        }
                      })
                      .filter(
                        (item) =>
                          (item?.first_name + " " + item?.last_name)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (item?.user_gender)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (item?.role)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase())
                      )
                      .map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i + "item"}>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 capitalize">
                                  {item?.first_name + " " + item?.last_name}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 mb_4">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format("LT")}
                                </label>
                                <label className="text-dark-50">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <label className="text-dark-50 mb_4">
                                    {item?.user_gender}
                                  </label>
                                </div>

                                <div className="flex_row_align_center">
                                  <label className="text-dark-50 mb_4">
                                    {item?.role}
                                  </label>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb_17">
                                  <AiTwotonePhone
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <label className="text-dark-50 mb_4">
                                    {item?.phone_number}
                                  </label>
                                </div>

                                <div className="flex_row_align_center">
                                  <AiTwotoneMail
                                    fill="#939393"
                                    width="9px"
                                    height="9px"
                                    className="mr_15"
                                  />

                                  <label className="text-dark-50">
                                    {item?.email}
                                  </label>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.balance &&
                                    numberWithCommas(parseInt(item?.balance))}
                                </span>

                                <div className="flex_row_right">
                                  <Link to={`#/`}>
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      View & Edit
                                    </p>
                                  </Link>
                                  <Link to={`#/`}>
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </table>
            <div className="p_15_40 flex_row_center">
              <Button
                className="red_button_thick width_full"
                text={
                  showAllTrips
                    ? `Hide all users  (${homeStore?.users?.length || 0})`
                    : `Show all users  (${homeStore?.users?.length || 0})`
                }
                onClick={() => {
                  setShowAllTrips(!showAllTrips);
                }}
              />
            </div>
            {showAllTrips ? (
              <table className="width_full slideup">
                <thead>
                  <tr>
                    <th className="pl-0">Name</th>
                    <th className="pl-0">Date Registered</th>{" "}
                    <th className="pl-0">Gender/Role</th>
                    <th className="pl-0">Contact</th>
                    <th className="px-0">Wallet Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <p className="gray_label bold_21px uppercase mt_25 pb_15">
                    {!loading && !homeStore?.users[0] ? "No User" : null}
                  </p>
                  {/* Loader */}
                  {loading ? (
                    <tr>
                      <td>
                        <div className="flex_column_left">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="flex_column_center">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="flex_column_left">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="flex_column_right">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {/* Loader End */}
                  {homeStore?.users[0] &&
                    homeStore?.users

                      .sort((a, b) => {
                        if (sortVal?.value === "name") {
                          return a?.first_name?.localeCompare(b?.first_name);
                        } else if (sortVal?.value === "balance") {
                          return parseFloat(b.balance) - parseFloat(a.balance);
                        } else if (sortVal?.value === "reg") {
                          return (
                            new Date(b.created_at) - new Date(a.created_at)
                          );
                        }
                      })
                      .filter(
                        (item) =>
                          (item?.first_name + " " + item?.last_name)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (item?.user_gender)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (item?.role)
                            .toUpperCase()
                            .includes(searchValue.toUpperCase())
                      )
                      .map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i + "item"}>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 capitalize">
                                  {item?.first_name + " " + item?.last_name}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 mb_4">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format("LT")}
                                </label>
                                <label className="text-dark-50">
                                  {item &&
                                    item.created_at &&
                                    moment(item.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <label className="text-dark-50 mb_4">
                                    {item?.user_gender}
                                  </label>
                                </div>

                                <div className="flex_row_align_center">
                                  <label className="text-dark-50 mb_4">
                                    {item?.role}
                                  </label>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb_17">
                                  <AiTwotonePhone
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <label className="text-dark-50 mb_4">
                                    {item?.phone_number}
                                  </label>
                                </div>

                                <div className="flex_row_align_center">
                                  <AiTwotoneMail
                                    fill="#939393"
                                    width="9px"
                                    height="9px"
                                    className="mr_15"
                                  />

                                  <label className="text-dark-50">
                                    {item?.email}
                                  </label>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.balance &&
                                    numberWithCommas(parseInt(item?.balance))}
                                </span>

                                <div className="flex_row_right">
                                  <Link to={`#/`}>
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      View & Edit
                                    </p>
                                  </Link>
                                  <Link to={`#/`}>
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            ) : null}
          </div>
        </section>
        {/* Left bottom section  end*/}
      </section>
    </Layout>
  );
};
export default withAlert()(UsersPage);
