import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import AlertCard from "../../components/card/notificationsCard";
import { withAlert } from "react-alert";
import db from "../../services/firebase.config";
import moment from "moment";
import Loader from "react-loader-spinner";
import "./styles.scss";
import CommonStore from "../../stores/superAdminStores/commonStore";
import homeStore from "../../stores/superAdminStores/homeStore";

const Notifications = ({ alert }) => {
  const partners = [{}, {}, {}];
  const [cokitchens, setCokitchens] = useState([]);
  const [trips, setTrips] = useState([]);
  const [activeTrip, setActiveTrip] = useState("");
  const [activePartner, setActivePartner] = useState("");
  const [location, setLocation] = useState("");
  const [refreshCount, setRefreshCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // load data from store
  useEffect(async () => {
    // if (!homeStore.dataCached) {
    await loadData();
    //  }
    setLoading(false);
  }, []);

  // load data from store
  const loadData = async () => {
    setLoading(true);
    let ongoing = [];
    homeStore.updateOngoingTrips([]);
    const response = db.collection("tracking_orders").onSnapshot(
      (items) => {
        ongoing = [];
        items.forEach((doc, i, arr) => {
          if (doc.data().completed) {
            ongoing.push(doc.data());
          }
        });
        setTrips(ongoing);
        homeStore.updateOngoingTrips(ongoing);
        console.log("Ongoing", ongoing);
      },
      (err) => {
        console.log(err);
      }
    );

    const data = await Promise.all([CommonStore.getCokitchens(alert)]);
    setLoading(false);

    setCokitchens(CommonStore.cokitchens);
  };

  let active = activePartner && activePartner;
  return (
    <Layout
      locationChange={setLocation}
      notifications="active_nav_item"
      withHistoryBtn
      title={<h6 className="bold_24px mr_30">Notifications & Alerts!</h6>}
    >
      <section className="main_section  content_section flex_column_start">
        <section className="flex_row_top tab_column mb_45 width_full">
          <section className="left_section width_full">
            {/* Left */}
            {!loading && trips.length === 0 ? (
              <div className="gray_label bold_24px mb_35">
                Notifications are empty
              </div>
            ) : null}
            {trips.map((item, i) => {
              return (
                <AlertCard
                  key={i + "card"}
                  name={item.name}
                  pathType="notification"
                  time={
                    item &&
                    item.order_type &&
                    item.order_type.created_at &&
                    moment(item.order_type.created_at).format("LT")
                  }
                  date={
                    item &&
                    item.order_type &&
                    item.order_type.created_at &&
                    moment(item.order_type.created_at).format("DD MMM YYYY")
                  }
                  id={item && item.order_code}
                  from={"CoKitchen"}
                  address={
                    item &&
                    item.calculated_order &&
                    item.calculated_order.address_details &&
                    item.calculated_order.address_details.address_line
                  }
                  price={
                    item &&
                    item.calculated_order &&
                    item.calculated_order.delivery_fee
                  }
                  toDestination={item.g}
                  logistics={item.g}
                  rider={
                    (item &&
                      item.rider &&
                      item.rider.first_name +
                        " " +
                        item.rider.last_name +
                        " (Rider)") ||
                    "No rider yet"
                  }
                />
              );
            })}
          </section>
        </section>

        {/* Table end */}
      </section>
    </Layout>
  );
};

export default withAlert()(Notifications);
