import React from "react";

// const logout = ({ history, alert }) => {
//   localStorage.removeItem("token");
//   localStorage.removeItem("id");
//   localStorage.removeItem("email");
//   localStorage.removeItem("first_name");
//   localStorage.removeItem("last_name");
//   localStorage.removeItem("role");
//   history.push("/login");
//   alert.show("Logged out successfully!", {
//     type: "success",
//   });
// };
const errorHandler = (error, alert) => {
  const errMsg =
    error && (error.response || error.message || error.toString() || error);
  let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
  console.log("Errror Handler one", errorMsg);
  if (typeof errorMsg !== "string") {
    alert.show("An error has occured!!", {
      type: "error",
    });
  } else {
    alert.show(errorMsg, {
      type: "error",
    });
    console.log("Errror Handler", errorMsg);
  }

  console.log(errMsg);
};
export default errorHandler;
