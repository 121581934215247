import axios from "axios";
import { withRouter } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const LOGIN_URL = "internal/login-admin";
const SIGNUP_URL = "internal/auth/register-logistics-admin";
const SIGNUP_SA_URL = "internal/auth/register-logistics-super-admin";
class AuthService {
  /***********************************Login********************* */
  login(data) {
    return axios
      .post(API_URL + LOGIN_URL, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      })
      .then((response) => {
        if (response.data) {
          let res = response.data;
          localStorage.setItem("token", res.token);
          localStorage.setItem("email", res.email);
          localStorage.setItem("first_name", res.first_name);
          localStorage.setItem("last_name", res.last_name);
        }

        return response;
      });
  }

  /***********************************Signup********************* */
  signup(data) {
    return axios
      .post(API_URL + SIGNUP_URL, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      })
      .then((response) => {
        if (response.data.access_token) {
          console.log(typeof response.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data));
        } else {
          console.log(response);
        }

        return response;
      });
  }

  /***********************************Signup Super admin********************* */
  signupSuperadmin(data) {
    return axios
      .post(API_URL + SIGNUP_SA_URL, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      })
      .then((response) => {
        if (response.data.access_token) {
          console.log(typeof response.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data));
        } else {
          console.log(response);
        }

        return response;
      });
  }
}

export default withRouter(new AuthService());
