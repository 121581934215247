import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import { GET_COKITCHENS_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class CommonStore {
  @observable cokitchens = [];
  @observable dataCached = false;

  getCokitchens = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getInternalData(GET_COKITCHENS_URL);

      runInAction(() => {
        let res = response.data && response.data.cokitchens;

        let i;
        let options = [];

        for (i = 0; i < res.length; i++) {
          options.push({ ...res[i], label: res[i].name, value: res[i].id });
        }
        this.cokitchens = options;
        shouldAlert &&
          alert.show("Cokitchens loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new CommonStore();
