import React from "react";
import "./styles.scss";
const OrderCard = ({ title, items }) => {
  return (
    <div className="order_card flex_column_left mb_25 width_full">
      <div className="order_card_title mb_15 flex_row_align_center p_20 width_full">
        <label className="normal_17px bold">{title}</label>
      </div>

      <div className="order_card_body px_10 width_full">
        {items.map((item, i) => {
          return (
            <div
              key={i + "items"}
              className="flex_row_start order_card_item py_15"
            >
              <h6 className="normal_17px bold red_text mr_25">
                {item && item.quantity + "×"}
              </h6>
              <div className="flex_column_left">
                <p className="normal_17px mb_5">{item && item.name}</p>
                {item &&
                  item.addons &&
                  item.addons.map((itm, ind) => {
                    return (
                      <label
                        className="normal_14px gray mt_5"
                        key={ind + "itm"}
                      >
                        {itm && itm.meal_data && itm.meal_data.name}{" "}
                        <span className="red_text">
                          x {itm && itm.quantity}
                        </span>
                      </label>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OrderCard;
