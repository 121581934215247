import React from "react";
import { MdDirectionsBike } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoMdWallet } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { ChevronRight, Clock, User } from "../../assets/icons/icons";
import { ONGOING_TRIP_DETAILS } from "../../router/router";
import "./styles.scss";
const AltCard = ({
  active,
  onClick,
  loading,
  time,
  date,
  id,
  from,
  address,
  price,
  toDestination,
  logistics,
  rider,
  status,
  pathType,
}) => {
  return loading ? (
    <div className="card flex_column_left bg_white p_20 mb_12 card_loader"></div>
  ) : (
    <table
      className={`width_full alt_card bg_white p_20 mb_12 ${
        active ? "alt_card_active" : ""
      }`}
      onClick={onClick}
    >
      <tbody>
        <tr>
          {/* One */}
          <td>
            <div className="flex_column_left">
              <label className="gray_label mb_4">{time}</label>
              <label className="gray_label mb_4">{date}</label>
              <label className="gray_label">#{id}</label>
              <div className="flex_row_right">
                <Link to={`/ongoing-trip-details/${pathType}/${id}`}>
                  <p className="mr_10 normal_15px black">
                    See full trip details
                  </p>
                </Link>
              </div>
            </div>
          </td>
          {/* Two */}
          <td>
            <div className="flex_column_left">
              <div className="flex_row_align_center mb_10">
                <span className="normal_53px green_text mr_10">•</span>
                <p className="normal_15px">
                  From: <span className="bold">{from}</span>
                </p>
              </div>

              <div className="flex_row_align_center">
                <span className="normal_53px red_text mr_10">•</span>
                <p className="normal_15px">
                  To: <span className="bold">{address}</span>
                </p>
              </div>

              <div className="flex_row_align_center">
                <div className="flex_row_align_center mr_30">
                  <IoMdWallet
                    fill=" #939393"
                    width="8px"
                    height="8px"
                    className="mr_15"
                  />

                  <p className="normal_15px bold">₦{price}</p>
                </div>

                <p className="normal_15px bold">
                  {toDestination} to destination
                </p>
              </div>
            </div>
          </td>
          {/* Three */}
          <td>
            <div className="flex_column_left">
              <div className="flex_row_align_center mb_17">
                <Clock
                  fill=" #939393"
                  width="8px"
                  height="8px"
                  className="mr_15"
                />

                <p
                  className={`normal_15px bold ${
                    status === "ongoing" ? "green_text" : "gray"
                  }`}
                >
                  {status === "ongoing" ? "on-going" : "pending"}
                </p>
              </div>

              <div className="flex_row_align_center mb_17">
                <HiOutlineOfficeBuilding
                  style={{
                    stroke: "#939393",
                  }}
                  width="9px"
                  height="9px"
                  className="mr_15"
                />

                <p className="normal_15px">{logistics}</p>
              </div>

              <div className="flex_row_align_center">
                <User
                  style={{
                    stroke: "#939393",
                  }}
                  width="9px"
                  height="9px"
                  className="mr_15"
                />

                <p className="normal_15px">{rider} </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default AltCard;
