import React from "react";
import "./styles.scss";
const Button = ({ text, onClick, className }) => {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      <label className="bold_18_px">{text}</label>
    </button>
  );
};

const IconButton = ({
  withIcon,
  icon,
  text,
  onClick,
  isBlue,
  isBlack,
  isRed,
  isRedThick,
  className,
  withSubIcon,
  subIcon,
  loading,
  rotate,
  type,
}) => {
  return (
    <button
      className={`button_two cursor_pointer ${
        isBlue
          ? "blue_button"
          : isRed
          ? "red_button"
          : isRedThick
          ? "red_button_thick"
          : isBlack
          ? "black_button"
          : "green_button"
      } ${className}`}
      //   onClick={onClick}
      disabled={loading}
      type={type}
      onClick={onClick}
    >
      {withIcon && <span className="mr_10">{icon}</span>}
      <h6 className="bold_18_px cursor_pointer m-0" onClick={onClick}>
        {text}
      </h6>
      {withSubIcon && loading && !rotate ? (
        <span className="ml_10">loading...</span>
      ) : withSubIcon && !loading ? (
        <span className="ml_10">{subIcon}</span>
      ) : null}
    </button>
  );
};
export { IconButton };
export default Button;
