import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
  CREATE_PARTNERS_URL,
  GET_PARTNERS_URL,
  UPDATE_PAYMENTS_URL,
  DELETE_PARTNER_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class PartnerStore {
  @observable partners = [];
  @observable dataCached = false;

  getPartners = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getAuthData(GET_PARTNERS_URL);

      runInAction(() => {
        let res = response.data && response.data.delivery_partners;

        this.partners = res;

        shouldAlert &&
          alert.show("Partners loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postPartners = async (alert, data, isUpdate) => {
    try {
      let response = await dataService.postAuthData(CREATE_PARTNERS_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;
        console.log(res);
        if (res) {
          isUpdate
            ? alert.show("Partner updated successfully!", {
                type: "success",
              })
            : alert.show("Partner created successfully!", {
                type: "success",
              });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updatePartner = async (alert, data, id) => {
    try {
      let response = await dataService.postAuthData(
        CREATE_PARTNERS_URL + id,
        data
      );
      let res;
      runInAction(() => {
        res = response && response.data;
        console.log(res);
        if (res) {
          alert.show("Partner updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updatePayment = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(UPDATE_PAYMENTS_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;
        console.log(res);
        if (res) {
          alert.show("Payment history updated successfully!", {
            type: "success",
          });
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteLogisticsPartner = async (alert, id) => {
    try {
      let response = await dataService.deleteAuthData(DELETE_PARTNER_URL + id);
      let res;
      runInAction(() => {
        res = response && response.data;
        console.log(res);
        if (res) {
          alert.show("Partner have been deleted successfully!", {
            type: "success",
          });
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new PartnerStore();
