import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-datetime/css/react-datetime.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-circular-progressbar/dist/styles.css"; //Progress bar css
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import App from "./App";
import HomePage from "./screens/homePage";
import MapPage from "./screens/mapPage";
import Login from "./screens/login";
import Signup from "./screens/signup";
// Super Admin
import SignupSuperAdmin from "./superAdminScreens/signup";
import SuperAdminHome from "./superAdminScreens/homePage";
import UsersPage from "./superAdminScreens/users";
import liveTrips from "./superAdminScreens/livetripHistoryFeedback";
import Notifications from "./superAdminScreens/notifications";
import PartnerDB from "./superAdminScreens/partnerDB";
import PaymentHistory from "./superAdminScreens/partnerHistory";
import FullTripDetails from "./superAdminScreens/fullTripDetails";
import OngoingTripDetail from "./superAdminScreens/ongoingTripDetail";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import {
  HOME_S_ADMIN,
  NOTIFICATIONS_S_ADMIN,
  PARTNER_DB_S_ADMIN,
  LIVE_TRIPS,
  PAYMENT_HISTORY,
  FULL_TRIP_DETAILS,
  ONGOING_TRIP_DETAILS,
  SIGNUP,
  SIGNUP_SUPER_ADMIN,
  USERS,
} from "./router/router";
import UnauthRoute from "./router/UnauthRoute";
import SuperAdminRoute from "./router/SuperAdminRoute";
import AdminRoute from "./router/AdminRoute";
import AuthRoute from "./router/AuthRoute";

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 10000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};
const AlertTemplate = ({ style, options, message, close }) => (
  <div
    style={style}
    className={`app_alert ${
      options.type === "info"
        ? "app_alert_info"
        : options.type === "success"
        ? "app_alert_success"
        : options.type === "error"
        ? "app_alert_error"
        : ""
    }`}
  >
    <span>
      {options.type === "info" && <BsInfoCircle />}
      {options.type === "success" && <BsCheckCircle />}
      {options.type === "error" && <BiErrorCircle />}
    </span>
    {message}
    <button onClick={close}></button>
  </div>
);
ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <React.StrictMode>
      <Router>
        <Switch>
          <UnauthRoute exact path="/" component={App} />
          {/* Auth */}
          <UnauthRoute exact path="/login" component={Login} />
          <UnauthRoute exact path={SIGNUP} component={Signup} />
          {/* Super-admin Auth */}

          <UnauthRoute
            exact
            path={SIGNUP_SUPER_ADMIN}
            component={SignupSuperAdmin}
          />

          {/* Super Admin */}

          <AuthRoute exact path={HOME_S_ADMIN} component={SuperAdminHome} />
          <AuthRoute exact path={LIVE_TRIPS} component={liveTrips} />
          <AuthRoute
            exact
            path={NOTIFICATIONS_S_ADMIN}
            component={Notifications}
          />
          <AuthRoute exact path={PARTNER_DB_S_ADMIN} component={PartnerDB} />
          <AuthRoute exact path={PAYMENT_HISTORY} component={PaymentHistory} />
          <AuthRoute
            exact
            path={FULL_TRIP_DETAILS}
            component={FullTripDetails}
          />

          <AuthRoute
            exact
            path={ONGOING_TRIP_DETAILS}
            component={OngoingTripDetail}
          />

          <AuthRoute exact path={USERS} component={UsersPage} />
        </Switch>
      </Router>
    </React.StrictMode>
  </AlertProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
