import React from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { HOME_S_ADMIN, LIVE_TRIPS, USERS } from "../../router/router";
import { HiHome } from "react-icons/hi";
import { RiEBikeFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
const SideNav = ({ closed }) => {
  return (
    <div
      className={`side-nav bg-white flex-column d-justify-start d-align-start ${
        closed || ""
      }`}
    >
      <NavLink
        activeClassName="nav-active"
        className="mb-1 no-underline d-justify-even d-align-center"
        to={HOME_S_ADMIN}
      >
        <div
          style={{
            marginLeft: "10px",
            marginRight: "auto",
          }}
          className="d-justify-start d-align-start text-purple-alt"
        >
          <HiHome className="mr-1" size="20" color="#551a8b" />
          <p className="bold ">Home</p>
        </div>
      </NavLink>
    </div>
  );
};

export default SideNav;
