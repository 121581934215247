import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import {
  ArrowBack,
  AppMap,
  Clock,
  User,
  Stars,
} from "../../assets/icons/icons";
import OrderCard from "../../components/orderCard";
import Button from "../../components/buttons";
import { useHistory } from "react-router-dom";
import { LIVE_TRIPS } from "../../router/router";
import Prompt from "../../components/modals/prompt";
import homeStore from "../../stores/superAdminStores/homeStore";
import { withAlert } from "react-alert";
import db from "../../services/firebase.config";
import "./styles.scss";
import moment from "moment";
import Map from "../../components/map";
import { numberWithCommas } from "../../helpers/commaSeparator";
import Layout from "../../components/layout";
const MapPage = ({ alert, ...props }) => {
  const history = useHistory();
  const path = props.match.params.id;
  const pathType = props.match.params.type;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [cancellationNote, setCancellationNote] = useState("");
  const [details, setDetails] = useState(null);
  const [orderTotal, setOrderTotal] = useState(null);
  const [activeRiderPos, setActiveRiderPos] = useState("");
  // load data from store
  useEffect(async () => {
    if (!homeStore.dataFBCached) {
      await loadData();
    }
    setLoading(false);

    findCurrentTrip();
  }, []);

  const loadData = async () => {
    setLoading(true);
    let ongoing = [];
    const response = await db.collection("tracking_orders").onSnapshot(
      (items) => {
        items.forEach((doc) => {
          console.log(doc.data());
          ongoing.push(doc.data());
        });
        findCurrentTrip(ongoing);
        homeStore.updateOngoingTrips(ongoing);
      },
      (err) => {
        console.log(err);
      }
    );

    setLoading(false);
  };
  // FInd currently selected trip
  const findCurrentTrip = async (
    allTrips = (homeStore && homeStore.ongoingTrips) || []
  ) => {
    console.log("AllTrips", allTrips);
    let active = await allTrips.find((item) => item.order_code === path);
    setDetails(active);
    if (active && active.rider && active.rider.id) {
      const response = await db
        .collection("rider_locations")
        .doc(active && active.rider && active.rider.id)
        .onSnapshot(
          (items) => {
            setActiveRiderPos(items.data());
            console.log("rider details", items.data());
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      setActiveRiderPos(null);
    }

    return active;
  };

  const formSubmit = async () => {
    const { cancelOrders } = homeStore;
    setSubmitting(true);

    await cancelOrders(
      alert,
      {
        order_code: details && details.order_code,
        cancelled_note: cancellationNote,
      },
      history
    );
    setSubmitting(false);

    setShowModal(false);
  };
  // Reducer function to compute total
  const getTotal = (items) => {
    let total = items.reduce(function (acc, curr) {
      return acc + curr.quantity;
    }, 0);
    return total;
  };

  // To calculate the total number of items bought
  const getItemsTotal = (orders) => {
    let i;
    let tot = [];
    for (i = 0; i < orders.length; i++) {
      tot.push(getTotal(orders[i].meals));
    }
    let allTotals = tot.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return allTotals;
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />
        <section className="content_section mt-5 pt-3">
          <div className="main_section_sec p_40">
            <div className="flex_row_between title_bar">
              <div className="flex_row_center">
                <button
                  className="mr_45 cursor_pointer"
                  onClick={() => history.push(LIVE_TRIPS)}
                >
                  <ArrowBack width="17px" height="17px" fill="#FF0000" />
                </button>

                <h6 className="bold_24px mr_10">#{path} </h6>

                <label className="gray normal_14px">
                  {moment(details?.kitchen_dispatched_time).format("LT")} |{" "}
                  {moment(details?.kitchen_dispatched_time).format(
                    "DD MMM YYYY"
                  )}
                </label>
              </div>
            </div>
            <section className="top_section_sec flex_row_between ml_75 mobile_column_wide">
              <div className="flex_column_left">
                <div className="flex_row_align_center">
                  <span className="normal_53px green_text mr_10">•</span>
                  <p className="normal_15px">
                    From: <span className="bold">CoKitchen Lekki</span>
                  </p>
                </div>

                <div className="flex_row_align_center">
                  <span className="normal_53px red_text mr_10">•</span>
                  <p className="normal_15px">
                    To:{" "}
                    <span className="bold">
                      {details?.calculated_order?.address_details?.address_line}
                    </span>
                  </p>
                </div>
              </div>
              {/* top middle */}
              <div className="flex_column_left">
                <div className="flex_row_align_center mb_15">
                  <span className="mr_10">
                    <Clock
                      fill=" rgba(0, 0, 0, 0.214)"
                      width="7px"
                      height="7px"
                    />
                  </span>
                  <p
                    className={`normal_15px bold ${
                      details && details.kitchen_dispatched
                        ? "green_text"
                        : "gray"
                    }`}
                  >
                    {details && details.kitchen_dispatched
                      ? "on-going"
                      : "pending"}
                  </p>
                </div>

                <div className="flex_row_align_center">
                  <span className="mr_10">
                    <User
                      fill="rgba(0, 0, 0, 0.214)"
                      width="7px"
                      height="7px"
                    />
                  </span>
                  <p className="normal_15px">
                    {(details &&
                      details.rider &&
                      details.rider.first_name +
                        " " +
                        details.rider.last_name +
                        " (Rider)") ||
                      "No rider yet"}
                  </p>
                </div>
              </div>

              {/* top last */}

              <div className="flex_column_right mobile_column_wide">
                <h6 className="bold_24px green_text mb_15">
                  ₦
                  {details?.calculated_order?.delivery_fee &&
                    numberWithCommas(
                      parseInt(details.calculated_order.delivery_fee)
                    )}
                </h6>
                <label className="normal_15px text_right">
                  Total trip time: 12mins <br />
                  Total trip distance: 2km
                </label>
              </div>
            </section>

            <section className="flex_row_top ml_35_0_0_75 tab_column">
              <section className="left_section_sec flex_column_left">
                {/* Order detail */}
                <div className="order_detail_table flex_column_left">
                  <div className="flex_row_between mb_45">
                    <p className="bold_21px mr_75">Order details</p>
                    <div className="flex_column_end">
                      <label className="bold_21px mb_5">
                        {(details?.calculated_order?.meals?.length > 1 &&
                          details.calculated_order.meals.length + " brands") ||
                          details?.calculated_order?.meals?.length + " brand"}
                        ,{" "}
                        {`${
                          details?.calculated_order?.meals &&
                          getItemsTotal(details?.calculated_order?.meals)
                        } ${
                          details?.calculated_order?.meals &&
                          getItemsTotal(details?.calculated_order?.meals) === 1
                            ? "item"
                            : "items"
                        }`}
                      </label>
                      <label className="bold_21px mb_5">
                        Order total: ₦
                        {details &&
                          details.calculated_order &&
                          details.calculated_order.total_amount &&
                          numberWithCommas(
                            details.calculated_order.total_amount
                          )}
                      </label>
                      <label className="bold_21px mb_5 green_text">
                        {details?.order_type?.name}
                      </label>
                      <label className="bold_21px mb_5">__</label>
                    </div>
                  </div>

                  {details?.calculated_order?.meals.map((item, i) => {
                    return (
                      <OrderCard
                        title={item && item.brand && item.brand.name}
                        items={item && item.meals}
                        key={"item" + i}
                      />
                    );
                  })}
                </div>
              </section>
              <section className="right_section_sec map_section flex_column_left">
                <div className="flex_row_between map_label">
                  <p className="mb_25 bold_21px">Trip route </p>
                </div>
                <Map
                  className="app_map app_map_detail"
                  // lat={
                  //   cokitchens &&
                  //   cokitchens[0] &&
                  //   cokitchens[0].lat &&
                  //   parseFloat(cokitchens[0].lat)
                  // }
                  // lng={
                  //   cokitchens &&
                  //   cokitchens[0] &&
                  //   cokitchens[0].lng &&
                  //   parseFloat(cokitchens[0].lng)
                  // }
                  userLat={
                    details &&
                    details.calculated_order &&
                    details.calculated_order.lat &&
                    parseFloat(details.calculated_order.lat)
                  }
                  userLng={
                    details &&
                    details.calculated_order &&
                    details.calculated_order.lng &&
                    parseFloat(details.calculated_order.lng)
                  }
                  kitchenLat={details && details.gg}
                  kitchenLng={details && details.gg}
                  riderLat={
                    activeRiderPos &&
                    activeRiderPos.lat &&
                    parseFloat(activeRiderPos.lat)
                  }
                  riderLng={
                    activeRiderPos &&
                    activeRiderPos.lat &&
                    parseFloat(activeRiderPos.lng)
                  }
                  dispatched={details && details.kitchen_dispatched}
                />

                <div className="p_20 mt_35 card flex_column_start mb_25 width_full">
                  <p className="mb_30 bold_21px">User information </p>
                  <div className="flex_row_between tab_column width_full">
                    <p className="normal_15px bold mb_15">
                      Name:{" "}
                      {details?.calculated_order?.user?.first_name +
                        " " +
                        details?.calculated_order?.user?.last_name}
                    </p>
                    <p className="normal_15px bold mb_15">
                      Email: {details?.calculated_order?.user?.email}
                    </p>
                  </div>
                  <div className="flex_row_between tab_column width_full mb_35">
                    <p className="normal_15px bold mb_15">
                      Phone: {details?.calculated_order?.user?.phone_number}
                    </p>
                    <p className="normal_15px bold mb_15">
                      user since:{" "}
                      {details?.calculated_order?.user?.created_at &&
                        moment(details.calculated_order.user.created_at).format(
                          "DD/MM/YYYY"
                        )}
                    </p>
                  </div>
                </div>
                <div className="width_full flex_row_center">
                  <Button
                    className="red_button_thick width_full"
                    text="Cancel trip"
                    onClick={() => setShowModal(true)}
                  />
                </div>
              </section>
            </section>
          </div>
          <Prompt
            show={showModal}
            closeModal={() => setShowModal(!showModal)}
            title="Please state your reason for cancelling this trip"
            onSubmit={(e) => {
              e.preventDefault();
              formSubmit();
            }}
            content={
              <textarea
                className="text_area text_area_short mb_15"
                placeholder="Type cancellation reason here "
                value={cancellationNote}
                onChange={(e) => setCancellationNote(e.target.value)}
                required
              />
            }
            saveText={submitting ? "Canceling..." : "Confirm cancellation"}
            loading={submitting}
            submitting={submitting}
            withGreen
            withRed
          />
        </section>
      </div>
    </main>
  );
};
export default withAlert()(MapPage);
