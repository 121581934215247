import React from "react";
import "./styles.scss";
const Hamburger = ({ close, click, className }) => (
  <button
    className={`hamburger_btn mr-5 ${close} ${className}`}
    onClick={click}
  >
    <div className="hamburger" />
    <div className="hamburger" />
    <div className="hamburger" />
  </button>
);
export default Hamburger;
