import React from "react";
import "./styles.scss";
import Loader from "react-loader-spinner";
const TextCard = ({
  cardLabel,
  withLink,
  link,
  cardValue,
  withFooter,
  cardFooter,
  className,
  loading,
}) => {
  return (
    <div
      className={`text_card_container px-1 flex-column d-justify-between d-align-center bg-white ${className}`}
    >
      <div className="flex_row_between width_full  py-1">
        <label className="bold_21px">{cardLabel}</label>
        {withLink && link}
      </div>
      <h6 className="h5 bold green_text mb-0s ease">
        {" "}
        {loading ? (
          <Loader type="ThreeDots" color="#1fc900 " height={10} width={50} />
        ) : (
          cardValue
        )}
      </h6>

      <label className="gray normal_16px mb-1">{cardFooter}</label>
    </div>
  );
};

export default TextCard;
