import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";

import errorHandler from "../../helpers/errorHandler";
export class PaystackStore {
  @observable banks = [];
  @observable dataCached = false;

  getBanks = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getPaystackData("/bank");

      runInAction(() => {
        let res = response?.data?.data;
        console.log("Bankss", res);
        this.banks = res;
        shouldAlert &&
          alert.show("Banks loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getUserBankDetails = async (alert, account_number, bank_code) => {
    try {
      let response = await dataService.getPaystackData(
        `/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`
      );
      let res;
      runInAction(() => {
        res = response?.data?.data;
      });

      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new PaystackStore();
