import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import TextCard from "../../components/textCard";

import { ChevronRight, Clock, User } from "../../assets/icons/icons";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import Live from "../../components/live";
import "./styles.scss";
import Caps from "../../components/tabs/capTabs";
import { IoMdWallet } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { FULL_TRIP_DETAILS } from "../../router/router";
import { withAlert } from "react-alert";
import homeStore from "../../stores/superAdminStores/homeStore";
import CommonStore from "../../stores/superAdminStores/commonStore";
import Loader from "react-loader-spinner";
import moment from "moment";
import RateCard from "../../components/rateCard";
import IconInput from "../../components/inputs/iconInput";
import { BiSearchAlt2 } from "react-icons/bi";
import { RiEBikeFill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
const HomePage = ({ alert }) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [showDateRange, setShowDateRange] = useState(true);
  const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brandDataLoading, setBrandDataLoading] = useState(false);
  const [mealDataloading, setMealDataLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [logistics, setLogistics] = useState(null);
  const [logisticsCopy, setLogisticsCopy] = useState(null);
  const [pendingTrips, setPendingTrips] = useState([]);
  const [bikesOnline, setBikesOnline] = useState([]);
  const [bikesOffline, setBikesOffline] = useState([]);
  const [ordersByBrand, setOrdersByBrand] = useState([]);
  const [ordersByMeal, setOrdersByMeal] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [ordersByBrandStats, setOrdersByBrandStats] = useState([]);
  const [ordersByMealStats, setOrdersByMealStats] = useState([]);
  const [ordersByMealStatsAll, setOrdersByMealStatsAll] = useState([]);

  const [todayTrips, setTodayTrips] = useState([]);
  const [yesterdayTrips, setYesterdayTrips] = useState([]);
  const [showTodayTrips, setShowTodayTrips] = useState(true);
  const [showYesterdayTrips, setShowYesterdayTrips] = useState(false);
  const [showAllTrips, setShowAllTrips] = useState(false);
  const [statsComputeLoading, setStatsComputeLoading] = useState(false);
  const [statsRestoreLoading, setStatsRestoreLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [refreshCount, setRefreshCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [showRestore, setShowRestore] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [allTimeRevenue, setAllTimeRevenue] = useState(0);
  const [revenueStats, setRevenueStats] = useState({
    revenue: 0,
    averageOrderValue: 0,
    highestOrderAmount: 0,
    lowestOrderAmount: 0,
  });

  const [statsValues, setStatsValues] = useState({
    pickup: 0,
    delivery: 0,
  });
  const [cashOrders, setCashOrders] = useState([]);
  const [paymentStats, setPaymentStats] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphSecData, setSecGraphData] = useState([]);
  const [graphDataStats, setGraphDataStats] = useState({ min: 0, max: 0 });
  const [graphSecDataStats, setGraphSecDataStats] = useState({
    min: 0,
    max: 0,
  });

  const [usersData, setUsersGraphData] = useState([]);
  const [usersSecData, setUsersSecGraphData] = useState([]);
  const [usersDataStats, setUsersGraphDataStats] = useState({ min: 0, max: 0 });
  const [usersSecDataStats, setUsersGraphSecDataStats] = useState({
    min: 0,
    max: 0,
  });

  const [cardOrders, setCardOrders] = useState([]);
  const [pickupOrders, setPickupOrders] = useState([]);
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [retentionRate, setRetentionRate] = useState(0);

  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
  const pieChartColors = [
    { color: "#E38627" },
    { color: "#DC143C" },
    { color: "#6A2135" },
    { color: "#e799a3" },
    { color: "#4cc552" },
  ];
  // const valid = (current) => {
  //   return current.isAfter(moment(startDate));
  // };

  // load data from store
  useEffect(async () => {
    await loadData();

    setLoading(false);

    setLogisticsCopy(homeStore.orders);
    await sortTrips();

    await sortMealBarChartData();
    await sortOrdersByDates(homeStore.orders);
    await extractUsersFromOrders(homeStore.orders);
    setCokitchens(CommonStore.cokitchens);
    setOrdersByMeal(homeStore.ordersByMeal);
  }, []);
  // Trigger Refresh
  useEffect(async () => {
    if (refreshCount) {
      await loadData();
      setLogisticsCopy(homeStore.orders);
      await sortTrips();

      await sortMealBarChartData();
      await sortOrdersByDates(homeStore.orders);
      await extractUsersFromOrders(homeStore.orders);
    }
  }, [refreshCount]);

  // Filter stats by date
  useEffect(async () => {
    if (filterCount > 1) {
      let tripsCopy = homeStore?.orders;
      let currentTrips = [];
      // Sort brand and meal orders

      sortMealBarChartData();
      for (let i = 0, len = tripsCopy.length; i < len; i++) {
        if (
          moment(
            moment(tripsCopy[i].created_at).format("YYYY-MM-DD")
          ).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ) {
          currentTrips.push(tripsCopy[i]);
        }
      }
      await extractUsersFromOrders(homeStore.orders);
      setLogistics(currentTrips);
    } else {
      // Sort brand and meal orders

      sortMealBarChartData();

      setLogistics(todayTrips);

      setSelectionRange({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      });
    }
  }, []);

  const loadActiveStats = async () => {
    try {
      setStatsComputeLoading(true);
      await computeActiveStats();
    } catch (error) {
      console.log(error);
    } finally {
      setStatsComputeLoading(false);
    }
  };
  // compute stats based on curent dates selected
  const computeActiveStats = async () => {
    return new Promise(async (resolve, reject) => {
      let tripsCopy = homeStore?.orders;
      let currentTrips = [];
      // Sort brand and meal orders

      await sortMealBarChartData();
      for (let i = 0, len = tripsCopy.length; i < len; i++) {
        if (
          moment(
            moment(tripsCopy[i].created_at).format("YYYY-MM-DD")
          ).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ) {
          currentTrips.push(tripsCopy[i]);
        }
      }
      await extractUsersFromOrders(homeStore.orders);
      setLogistics(currentTrips);

      setFilterCount(0);
      setShowRestore(true);

      if (currentTrips) {
        resolve(currentTrips);
      } else {
        reject(new Error("Unable to compute stats"));
      }
    });
  };
  // Restore to today stats
  const restoreStats = async () => {
    // Sort brand and meal orders
    setStatsRestoreLoading(true);

    sortMealBarChartData();

    setLogistics(todayTrips);

    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setStatsRestoreLoading(false);
    setShowRestore(false);
  };

  // load data from store
  const loadData = async () => {
    setLoading(true);
    //  setBrandDataLoading(true);
    setMealDataLoading(true);
    if (!homeStore.dataMealsCached || filterCount > 1) {
      await homeStore.getOrdersByMeals(alert);
    }
    setOrdersByMeal(homeStore.ordersByMeal);
    await sortMealBarChartData();
    setMealDataLoading(false);
    // Get all orders
    if (!homeStore.dataCached || filterCount > 1) {
      await homeStore.getAdminOrders(alert, true, moment);
    }
    setLoading(false);
    setLogisticsCopy(homeStore.orders);
    await sortTrips();
    await sortOrdersByDates(homeStore.orders);
    await extractUsersFromOrders(homeStore.orders);

    await CommonStore.getCokitchens(alert);
    setCokitchens(CommonStore.cokitchens);
    setLoading(false);
  };

  // Reducer function to compute total
  const getTotal = (items = []) => {
    let total =
      items && items[0]
        ? items.reduce(function (acc, curr) {
            return (
              acc +
              parseFloat(curr?.calculated_order?.total_amount) +
              parseFloat(curr?.change || 0)
            );
          }, 0)
        : 0;
    return total;
  };
  // Get minimum and max revenues
  const getMinMax = (items = []) => {
    let min = parseFloat(
        items && items[0] && items[0].calculated_order?.total_amount
      ),
      max = parseFloat(
        items && items[0] && items[0].calculated_order?.total_amount
      );

    for (let i = 1; i < items.length; i++) {
      let value = parseFloat(items[i].calculated_order?.total_amount);
      min = value < min ? value : min;
      max = value > max ? value : max;
    }
    return [min, max];
  };

  // Sort meal orders
  const sortMealBarChartData = (mealOrdersCopy = homeStore?.ordersByMeal) => {
    let currentMealOrdersArr = [];
    let currentMealOrdersCountArr = [];
    let currentMealOrdersCountArrAll = [];

    // Sort meals orders by date
    for (let i = 0, len = mealOrdersCopy.length; i < len; i++) {
      currentMealOrdersArr.push({
        mealOrders: mealOrdersCopy[i]?.orders?.filter((item, j) =>
          moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ),
        mealDetail: mealOrdersCopy[i]?.meal,
      });
    }

    currentMealOrdersArr = currentMealOrdersArr.sort(
      (a, b) => b?.mealOrders?.length - a?.mealOrders?.length
    );
    // Sort meals orders by date
    for (let i = 0, len = currentMealOrdersArr.length; i < len; i++) {}

    let ind = 0;

    while (ind < 5) {
      currentMealOrdersCountArr.push({
        title: currentMealOrdersArr[ind]?.mealDetail?.name,
        value: currentMealOrdersArr[ind]?.mealOrders?.length,
        color: pieChartColors[ind].color,
      });
      ind++;
    }

    for (let i = 0, len = currentMealOrdersArr.length; i < len; i++) {
      currentMealOrdersCountArrAll.push({
        title: currentMealOrdersArr[i]?.mealDetail?.name,
        value: currentMealOrdersArr[i]?.mealOrders?.length,
      });
    }
    setOrdersByMealStats(currentMealOrdersCountArr);
    setOrdersByMealStatsAll(currentMealOrdersCountArrAll);
    console.log(
      "currentMealsOrdersArr",
      mealOrdersCopy,
      currentMealOrdersArr,
      currentMealOrdersCountArr
    );
  };

  // Get minimum and max revenues for the graph
  const getMinMaxAlt = (items = []) => {
    let min = items && items[0] && items[0],
      max = items && items[0] && items[0];
    let minObj, maxObj;

    for (let i = 1; i < items?.length; i++) {
      let value = items[i];
      min =
        parseFloat(value.y) < parseFloat(min?.y || min)
          ? parseFloat(value.y)
          : parseFloat(min?.y || min);
      max =
        parseFloat(value.y) > parseFloat(max?.y || max)
          ? parseFloat(value.y)
          : parseFloat(max?.y || max);
    }
    for (let i = 0; i < items.length; i++) {
      if (parseFloat(items[i].y) === min) {
        minObj = items[i];
      } else if (parseFloat(items[i].y) === max) {
        maxObj = items[i];
      }
    }

    console.log("Graph minmax", [minObj, maxObj], min, max);
    return [minObj, maxObj];
  };
  // Sort Revenue by date

  // Sort trips into today, yesterday and all time, cash, card
  const sortTrips = async () => {
    let todTrips = [];
    let yesTrips = [];

    let allTrips = homeStore?.orders || [];

    for (let i = 0; i < allTrips.length; i++) {
      if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
        todTrips.push(allTrips[i]);
      } else if (
        moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
      ) {
        yesTrips.push(allTrips[i]);
      }
    }
    // Sort brand and meal orders

    setLogistics(todTrips);
    setTodayTrips(todTrips);
    setYesterdayTrips(yesTrips);
  };

  // Sort orders into a list of their common dates
  const sortOrdersByDates = (items = []) => {
    let currentGraphTrips = [];
    let currentGraphSecTrips = [];
    let allOrdersAlt =
      items &&
      items[0] &&
      Array.from(
        items &&
          items.reduce(
            (acc, o) => (
              acc.get(moment(o.created_at).format("YYYY-MM-DD")).push(o), acc
            ),
            new Map(
              items &&
                items.map((o) => [
                  moment(o.created_at).format("YYYY-MM-DD"),
                  [],
                ])
            )
          ),
        ([key, value]) => value
      );

    for (let i = 0; i < allOrdersAlt?.length; i++) {
      currentGraphTrips.push({
        x: moment(allOrdersAlt[i][0].created_at).format("DD/MM/YY"),
        y: getTotal(allOrdersAlt[i]),
        date: allOrdersAlt[i][0].created_at,
      });

      currentGraphSecTrips.push({
        x: moment(allOrdersAlt[i][0].created_at).format("DD/MM/YY"),
        y: allOrdersAlt[i].length,
        date: allOrdersAlt[i][0].created_at,
      });
    }
    let minMax = getMinMaxAlt(currentGraphTrips);
    let minMaxSec = getMinMaxAlt(currentGraphSecTrips);
    setGraphData(currentGraphTrips);
    setSecGraphData(currentGraphSecTrips);

    setGraphDataStats({ ...graphDataStats, min: minMax[0], max: minMax[1] });
    setGraphSecDataStats({
      ...graphSecDataStats,
      min: minMaxSec[0],
      max: minMaxSec[1],
    });
    return currentGraphTrips;
  };
  // Set current filter date
  const setFilterDate = () => {
    let currDate = "";
    if (
      moment(selectionRange?.startDate).format("DD-MM-YYYY") === today &&
      moment(selectionRange?.endDate).format("DD-MM-YYYY") === today
    ) {
      currDate = "Today";
    } else if (
      moment(selectionRange?.startDate).format("DD-MM-YYYY") === yesterday &&
      moment(selectionRange?.endDate).format("DD-MM-YYYY") === yesterday
    ) {
      currDate = "Yesterday";
    } else {
      currDate =
        moment(selectionRange?.startDate).format("D MMM 'YY") +
        " - " +
        moment(selectionRange?.endDate).format("D MMM 'YY");
    }

    return currDate;
  };

  // Extract users from orders
  const extractUsersFromOrders = (items = []) => {
    let currentGraphTrips = [];
    let currentGraphSecTrips = [];
    items =
      items[0] &&
      items?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    let allOrdersAlt =
      items &&
      items[0] &&
      Array.from(
        items &&
          items.reduce(
            (acc, o) => (
              acc
                .get(
                  o?.calculated_order?.user?.first_name +
                    " " +
                    o?.calculated_order?.user?.last_name
                )
                .push(o),
              acc
            ),
            new Map(
              items &&
                items.map((o) => [
                  o?.calculated_order?.user?.first_name +
                    " " +
                    o?.calculated_order?.user?.last_name,
                  [],
                ])
            )
          ),
        ([key, value]) => {
          return {
            orders: value,
            ordersLength: value?.length,
            user:
              value[0]?.calculated_order?.user?.first_name +
              " " +
              value[0]?.calculated_order?.user?.last_name,
            date: value[0]?.created_at,
            total: getTotal(value),
          };
        }
      );

    console.log("All orders by users", allOrdersAlt?.length);

    for (let i = 0; i < allOrdersAlt?.length; i++) {
      currentGraphTrips.push({
        x:
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.first_name +
          " " +
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.last_name,
        y: getTotal(allOrdersAlt[i]?.orders),
      });

      currentGraphSecTrips.push({
        x:
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.first_name +
          " " +
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.last_name,
        y: allOrdersAlt[i]?.orders.length,
      });
    }
    let minMax = getMinMaxAlt(currentGraphTrips);
    let minMaxSec = getMinMaxAlt(currentGraphSecTrips);
    setUsersGraphData(currentGraphTrips);
    setUsersSecGraphData(currentGraphSecTrips);

    setUsersGraphDataStats({
      ...graphDataStats,
      min: minMax[0],
      max: minMax[1],
    });
    setUsersGraphSecDataStats({
      ...graphSecDataStats,
      min: minMaxSec[0],
      max: minMaxSec[1],
    });

    calcRetentionRate(allOrdersAlt);
    return allOrdersAlt;
  };

  // Calculate retention rate

  const calcRetentionRate = (items = []) => {
    let thisweekOrders = [],
      retentionR = 0;
    for (let i = 0, len = items.length; i < len; i++) {
      if (
        moment(moment(items[i].date).format("YYYY-MM-DD")).isBetween(
          moment(moment(moment().add(-7, "days")).subtract(1, "d")).format(
            "YYYY-MM-DD"
          ),
          moment(moment().add(1, "d")).format("YYYY-MM-DD")
        )
      ) {
        thisweekOrders.push(items[i]);
      }
    }

    retentionR =
      parseFloat(
        ((thisweekOrders?.length || 0) / (items?.length || 0)) * 100
      ).toFixed(1) || 0;
    setRetentionRate(retentionR);
    console.log(
      "Last seven days orders",
      moment(moment(moment().add(-7, "days")).subtract(1, "d")),
      moment(moment().add(1, "d")).format("YYYY-MM-DD"),
      today,
      items,
      retentionR,
      thisweekOrders
    );
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setFilterCount((prev) => prev + 1);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <Layout
      locationChange={setLocation}
      home="active_nav_item"
      options={cokitchens}
      onRefresh={() => window.location.reload()}
      refreshLoading={refreshCount && loading}
    >
      <section className="flex-column w-100 d-justify start d-align-start content_section main_section">
        <section className=" flex_row_top  tab_column mb_45 w-100">
          <section className="left_section pr_25 width_full">
            <section className="top_left grid_third mb_35"></section>

            {/* Left middle start */}
            <div className="d-justify-start d-align-start mb-4 mt-2">
              <RateCard
                type="pie"
                cardLabel="Top Meals"
                pieData={ordersByMealStats
                  .sort((a, b) => b.value - a.value)
                  .filter((item) => item.value !== undefined)}
                date={setFilterDate()}
                className=""
                loading={mealDataloading}
                rateTwo="See all meals"
                footerClass="gray underline pointer"
                modalTitle={`Full Meals Order History Breakdown: ${setFilterDate()} `}
                modalBody={ordersByMealStatsAll}
                modalType="meal"
                ordersCount={logistics?.length || "0"}
                ordersLoading={loading}
              />
            </div>

            {/* Left middle end */}

            {/* Left bottom section start*/}

            {/* Left bottom section  end*/}
          </section>

          <section className="right_section px_10 second_width_desktop">
            <div className="flex_row_right mb_25">
              <a
                href="#/"
                onClick={() => {
                  setLogistics(homeStore?.orders);

                  setFilterCount((prev) => prev + 1);
                  setSelectionRange(homeStore?.ordersDateRange);
                }}
              >
                <label className="gray_label text-white bold cursor_pointer">
                  Show all time data
                </label>
              </a>
            </div>

            <div
              className="button red_border borderless_dates flex_row_center mb_30"
              onClick={() => {
                if (showDateRange) {
                  return;
                } else {
                  setShowDateRange(true);
                }
              }}
            >
              {showDateRange && (
                <div
                  className={showDateRangeFilters ? "" : "collapsed_date_range"}
                >
                  <div className="flex_row_between">
                    <button
                      className="cursor_pointer mb_12 date_filter_button"
                      onClick={() =>
                        setShowDateRangeFilters(!showDateRangeFilters)
                      }
                    >
                      {showDateRangeFilters
                        ? "Hide date filters"
                        : " Show date filters"}
                    </button>
                    {filterCount > 1 ? (
                      <button
                        className="cursor_pointer mb_12 date_apply_button"
                        onClick={() => loadActiveStats()}
                        disabled={statsComputeLoading}
                      >
                        {statsComputeLoading
                          ? "Applying date filter..."
                          : "Apply date filter"}
                      </button>
                    ) : null}

                    {showRestore ? (
                      <button
                        className="cursor_pointer mb_12 date_clear_button"
                        onClick={() => restoreStats()}
                        disabled={statsRestoreLoading}
                      >
                        {statsRestoreLoading
                          ? "Restoring stats..."
                          : "Clear date filter"}
                      </button>
                    ) : null}
                  </div>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>

            {/* Summary item */}
            <div className="flex_column"></div>
          </section>
        </section>

        {/* Orders history start */}
        <section className="bottom_left_section bg-white w-100 position-relative">
          <div className="border_bottom_thin flex_row_between pb-3 w-100">
            <h5 className="mb_15">Order history</h5>
            {/* <Link
              to="#/"
              onClick={() => {
                if (showAll) {
                  setShowAll(false);
                  setShowTodayTrips(false);
                  setShowYesterdayTrips(false);
                  setShowAllTrips(false);
                } else {
                  setShowAll(true);
                  setShowTodayTrips(true);
                  setShowYesterdayTrips(true);
                  setShowAllTrips(true);
                }
              }}
            >
              <p className="gray_label mb_15 bold">
                {showAll ||
                (showAllTrips && showTodayTrips && showYesterdayTrips)
                  ? "Collapse all"
                  : "Show all"}
              </p>
            </Link> */}
            {/* <Select
              containerClass="w-30"
              placeholder="Locations: ALL"
              defaultValue={{ label: "Sort By: Name", value: "name" }}
              handleChange={(e) => setSortVal(e)}
              options={[
                { label: "Sort By: Name", value: "name" },
                { label: "Sort By: Wallet Balance", value: "balance" },
                { label: "Sort By: Registration Date", value: "reg" },
              ]}
            /> */}
            <IconInput
              containerClass="w-50"
              placeholder="Search order by code, customer/rider name or address"
              withIcon
              icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
              value={searchValue}
              onChange={(e) => setSearchValue(e)}
            />
          </div>
          <div className="overflow-scroll w-100">
            <div className="border_bottom_thin flex_row_between py_15">
              <p className="bold_18_px">Today ({todayTrips.length})</p>
              <Link to="#/" onClick={() => setShowTodayTrips((prev) => !prev)}>
                <p className="gray_label mb_15 bold">
                  {showTodayTrips ? "Collapse" : "Display"}
                </p>
              </Link>
            </div>

            <table className="width_full">
              <tbody>
                <p className="gray_label bold_21px uppercase mt_25 pb_15">
                  {!loading && todayTrips.length === 0
                    ? "No trip has been recorded today"
                    : null}
                </p>
                {/* Loader */}
                {loading ? (
                  <tr>
                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_center">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex_column_right">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
                {/* Loader End */}
                {showTodayTrips
                  ? todayTrips

                      .sort(
                        (a, b) =>
                          new Date(b.completed_time) -
                          new Date(a.completed_time)
                      )

                      .filter(
                        (item) =>
                          (
                            item?.rider?.first_name +
                            " " +
                            item?.rider?.last_name
                          )
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          item?.order_code
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (
                            item?.calculated_order?.user?.first_name +
                            " " +
                            item?.calculated_order?.user?.last_name
                          )
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          item?.calculated_order?.address_details?.address_line
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase())
                      )
                      .map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i + "item"}>
                            <td>
                              <div className="flex_column_left">
                                <label className="gray_label mb_4">
                                  {item &&
                                    item.completed_time &&
                                    moment(item.completed_time).format("LT")}
                                </label>
                                <label className="gray_label mb_4">
                                  {item &&
                                    item.completed_time &&
                                    moment(item.completed_time).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                                <label className="gray_label">
                                  #{item?.order_code}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <span className="normal_53px green_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px mb-0">
                                    From:{" "}
                                    <span className="bold">CoKitchen</span>
                                  </p>
                                </div>

                                <div className="flex_row_align_center">
                                  <span className="normal_53px red_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px mb-0">
                                    To:{" "}
                                    <span className="bold">
                                      {item &&
                                        item.calculated_order &&
                                        item.calculated_order.address_details &&
                                        item.calculated_order.address_details
                                          .address_line}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb-1">
                                  <Clock
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <p
                                    className={`normal_15px bold mb-0 ${
                                      status === "Completed"
                                        ? "green_text"
                                        : status === "Cancelled"
                                        ? "red_text"
                                        : "gray"
                                    }`}
                                  >
                                    {status}
                                  </p>
                                </div>

                                <div className="flex_row_center mb-1">
                                  {item?.calculated_order?.pickup ? (
                                    <GiCardPickup
                                      color="#939393"
                                      size="14px"
                                      className="mr_15"
                                    />
                                  ) : (
                                    <RiEBikeFill
                                      color="#939393"
                                      size="14px"
                                      className="mr_15"
                                    />
                                  )}
                                  <p className="normal_15px mb-0">
                                    {(item &&
                                      item.rider &&
                                      item.rider.first_name +
                                        " " +
                                        item.rider.last_name) ||
                                      "PICKUP"}
                                  </p>
                                </div>

                                <div className="flex_row_center">
                                  <FaUser
                                    color="#939393"
                                    size="11px"
                                    className="mr_15"
                                  />

                                  <p className="normal_15px mb-0">
                                    {item?.calculated_order?.user?.first_name +
                                      " " +
                                      item?.calculated_order?.user?.last_name}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.calculated_order?.delivery_fee &&
                                    numberWithCommas(
                                      parseInt(
                                        item.calculated_order.delivery_fee
                                      )
                                    )}
                                </span>

                                <div className="flex_row_right">
                                  <Link
                                    to={`full-trip-details/home/${item?.order_code}`}
                                  >
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      See full trip details
                                    </p>
                                  </Link>
                                  <Link
                                    to={`full-trip-details/home/${item?.order_code}`}
                                  >
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </table>

            <div className="border_bottom_thin flex_row_between py_15">
              <p className="bold_18_px">Yesterday ({yesterdayTrips.length})</p>

              <Link
                to="#/"
                onClick={() => setShowYesterdayTrips((prev) => !prev)}
              >
                <p className="gray_label mb_15 bold">
                  {showYesterdayTrips ? "Collapse" : "Display"}
                </p>
              </Link>
            </div>
            <table className="width_full">
              <tbody>
                <p className="gray_label bold_21px uppercase mt_25 pb_15">
                  {!loading && yesterdayTrips.length === 0
                    ? "No trips were recorded for yesterday"
                    : null}
                </p>
                {/* Loader */}
                {loading ? (
                  <tr>
                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_column_center">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex_column_left">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="flex_column_right">
                        <Loader
                          type="ThreeDots"
                          color="#ff000060"
                          height={10}
                          width={50}
                        />
                      </div>
                    </td>
                  </tr>
                ) : null}
                {/* Loader End */}
                {showYesterdayTrips
                  ? yesterdayTrips
                      .sort(
                        (a, b) =>
                          new Date(b.completed_time) -
                          new Date(a.completed_time)
                      )
                      .filter(
                        (item) =>
                          (
                            item?.rider?.first_name +
                            " " +
                            item?.rider?.last_name
                          )
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          item?.order_code
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (
                            item?.calculated_order?.user?.first_name +
                            " " +
                            item?.calculated_order?.user?.last_name
                          )
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          item?.calculated_order?.address_details?.address_line
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase())
                      )
                      .map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i + "item"}>
                            <td>
                              <div className="flex_column_left">
                                <label className="gray_label mb_4">
                                  {item &&
                                    item.completed_time &&
                                    moment(item.completed_time).format("LT")}
                                </label>
                                <label className="gray_label mb_4">
                                  {item &&
                                    item.completed_time &&
                                    moment(item.completed_time).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                                <label className="gray_label">
                                  #{item?.order_code}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <span className="normal_53px green_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px mb-0">
                                    From:{" "}
                                    <span className="bold">CoKitchen</span>
                                  </p>
                                </div>

                                <div className="flex_row_align_center">
                                  <span className="normal_53px red_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px mb-0">
                                    To:{" "}
                                    <span className="bold">
                                      {item &&
                                        item.calculated_order &&
                                        item.calculated_order.address_details &&
                                        item.calculated_order.address_details
                                          .address_line}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb-1">
                                  <Clock
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <p
                                    className={`normal_15px bold mb-0 ${
                                      status === "Completed"
                                        ? "green_text"
                                        : status === "Cancelled"
                                        ? "red_text"
                                        : "gray"
                                    }`}
                                  >
                                    {status}
                                  </p>
                                </div>

                                <div className="flex_row_center mb-1">
                                  {item?.calculated_order?.pickup ? (
                                    <GiCardPickup
                                      color="#939393"
                                      size="14px"
                                      className="mr_15"
                                    />
                                  ) : (
                                    <RiEBikeFill
                                      color="#939393"
                                      size="14px"
                                      className="mr_15"
                                    />
                                  )}
                                  <p className="normal_15px mb-0">
                                    {(item &&
                                      item.rider &&
                                      item.rider.first_name +
                                        " " +
                                        item.rider.last_name) ||
                                      "PICKUP"}
                                  </p>
                                </div>

                                <div className="flex_row_center">
                                  <FaUser
                                    color="#939393"
                                    size="11px"
                                    className="mr_15"
                                  />

                                  <p className="normal_15px mb-0">
                                    {item?.calculated_order?.user?.first_name +
                                      " " +
                                      item?.calculated_order?.user?.last_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            {/* Test */}

                            {/* Test */}
                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.calculated_order?.delivery_fee &&
                                    numberWithCommas(
                                      parseInt(
                                        item.calculated_order.delivery_fee
                                      )
                                    )}
                                </span>

                                <div className="flex_row_right">
                                  <Link
                                    to={`full-trip-details/home/${item?.order_code}`}
                                  >
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      See full trip details
                                    </p>
                                  </Link>
                                  <Link
                                    to={`full-trip-details/home/${item?.order_code}`}
                                  >
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </table>
            <div className="p_15_40 flex_row_center">
              <Button
                className="red_button_thick width_full"
                text={
                  showAllTrips
                    ? `Hide full trip history  (${
                        homeStore?.orders?.length || 0
                      })`
                    : `Show full trip history  (${
                        homeStore?.orders?.length || 0
                      })`
                }
                onClick={() => {
                  setShowAllTrips(!showAllTrips);
                }}
              />
            </div>
            {showAllTrips ? (
              <table className="width_full slideup">
                <tbody>
                  <p className="gray_label bold_21px uppercase mt_25 pb_15">
                    {!loading && !homeStore?.orders[0]
                      ? "No trips has been recorded"
                      : null}
                  </p>
                  {/* Loader */}
                  {loading ? (
                    <tr>
                      <td>
                        <div className="flex_column_left">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="flex_column_center">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="flex_column_left">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="flex_column_right">
                          <Loader
                            type="ThreeDots"
                            color="#ff000060"
                            height={10}
                            width={50}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {/* Loader End */}
                  {homeStore?.orders[0] &&
                    homeStore?.orders

                      .sort(
                        (a, b) =>
                          new Date(b.completed_time) -
                          new Date(a.completed_time)
                      )
                      .filter(
                        (item) =>
                          (
                            item?.rider?.first_name +
                            " " +
                            item?.rider?.last_name
                          )
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          item?.order_code
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          (
                            item?.calculated_order?.user?.first_name +
                            " " +
                            item?.calculated_order?.user?.last_name
                          )
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase()) ||
                          item?.calculated_order?.address_details?.address_line
                            ?.toUpperCase()
                            .includes(searchValue.toUpperCase())
                      )
                      .map((item, i) => {
                        let status =
                          item && item.completed
                            ? "Completed"
                            : item && item.cancelled
                            ? "Cancelled"
                            : item && !item.completed && !item.cancelled
                            ? "Pending"
                            : "";
                        return (
                          <tr key={i + "item"}>
                            <td>
                              <div className="flex_column_left">
                                <label className="text-dark-50 mb_4">
                                  {item &&
                                    item.completed_time &&
                                    moment(item.completed_time).format("LT")}
                                </label>
                                <label className="text-dark-50 mb_4">
                                  {item &&
                                    item.completed_time &&
                                    moment(item.completed_time).format(
                                      "DD MMM YYYY"
                                    )}
                                </label>
                                <label className="text-dark-50">
                                  #{item?.order_code}
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_align_center mb_10">
                                  <span className="normal_53px green_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px mb-0 text-dark-50">
                                    From:{" "}
                                    <span className="bold">CoKitchen</span>
                                  </p>
                                </div>

                                <div className="flex_row_align_center">
                                  <span className="normal_53px red_text mr_10">
                                    •
                                  </span>
                                  <p className="normal_15px mb-0">
                                    To:{" "}
                                    <span className="bold">
                                      {item &&
                                        item.calculated_order &&
                                        item.calculated_order.address_details &&
                                        item.calculated_order.address_details
                                          .address_line}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_left">
                                <div className="flex_row_center mb-1">
                                  <Clock
                                    fill="#939393"
                                    width="8px"
                                    height="8px"
                                    className="mr_15"
                                  />

                                  <p
                                    className={`normal_15px bold mb-0 ${
                                      status === "Completed"
                                        ? "green_text"
                                        : status === "Cancelled"
                                        ? "red_text"
                                        : "gray"
                                    }`}
                                  >
                                    {status}
                                  </p>
                                </div>

                                <div className="flex_row_center mb-1">
                                  {item?.calculated_order?.pickup ? (
                                    <GiCardPickup
                                      color="#939393"
                                      size="14px"
                                      className="mr_15"
                                    />
                                  ) : (
                                    <RiEBikeFill
                                      color="#939393"
                                      size="14px"
                                      className="mr_15"
                                    />
                                  )}
                                  <p className="normal_15px mb-0">
                                    {(item &&
                                      item.rider &&
                                      item.rider.first_name +
                                        " " +
                                        item.rider.last_name) ||
                                      "PICKUP"}
                                  </p>
                                </div>

                                <div className="flex_row_center">
                                  <FaUser
                                    color="#939393"
                                    size="11px"
                                    className="mr_15"
                                  />

                                  <p className="normal_15px mb-0">
                                    {item?.calculated_order?.user?.first_name +
                                      " " +
                                      item?.calculated_order?.user?.last_name}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="flex_column_right">
                                <span className="bold_21px green_text mb_17">
                                  ₦
                                  {item?.calculated_order?.delivery_fee &&
                                    numberWithCommas(
                                      parseInt(
                                        item.calculated_order.delivery_fee
                                      )
                                    )}
                                </span>

                                <div className="flex_row_right">
                                  <Link
                                    to={`full-trip-details/home/${item?.order_code}`}
                                  >
                                    {" "}
                                    <p className="mr_10 normal_15px black">
                                      See full trip details
                                    </p>
                                  </Link>
                                  <Link
                                    to={`full-trip-details/home/${item?.order_code}`}
                                  >
                                    <ChevronRight
                                      fill="#000000"
                                      width="11px"
                                      height="11px"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            ) : null}
          </div>
        </section>
        {/* Orders history end */}
      </section>
    </Layout>
  );
};

export default withAlert()(HomePage);
