import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import Card from "../../components/card";
//import DateTime from "react-datetime";
import { Link } from "react-router-dom";
//import moment from "moment";
import { DateRangePicker } from "react-date-range";
import Live from "../../components/live";
import "./styles.scss";
import { HiPlus } from "react-icons/hi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Input from "../../components/inputs";
import { IconButton } from "../../components/buttons";
import Modal from "../../components/modals";
import IconInput from "../../components/inputs/iconInput";
import CommonStore from "../../stores/superAdminStores/commonStore";
import Select from "../../components/select";
//import PageLoading from "../../components/loader";
import { withAlert } from "react-alert";
import partnerStore from "../../stores/superAdminStores/partnerStore";
import paystackStore from "../../stores/superAdminStores/paystackStore";
import homeStore from "../../stores/superAdminStores/homeStore";
import { numberWithCommas } from "../../helpers/commaSeparator";
import Prompt from "../../components/modals/prompt";
import ImageFile from "../../components/imageFile";
import axios from "axios";
import errorHandler from "../../helpers/errorHandler";

const PartnerDB = ({ alert }) => {
  const [partners, setPartners] = useState([]);

  const [activePartner, setActivePartner] = useState("");

  const [location, setLocation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [locations, setLocations] = useState([]);
  const [ridersOnline, setRidersOnline] = useState([]);
  const [ridersOffline, setRidersOffline] = useState([]);
  const [currentRiders, setCurrentRiders] = useState([]);
  const [currentAdmins, setCurrentAdmins] = useState([]);
  const [currentAdminsAlt, setCurrentAdminsAlt] = useState([]);
  const [paymentForm, setPaymentForm] = useState({ amount: "", note: "" });
  const [logisticsCompany, setLogisticsCompany] = useState({
    contact_phone_number: "",
    name: "",
    address: "",
    contact_email: "",
    payout_bank_name: "",
    payout_account_name: "",
    payout_account_number: "",
    payout_bank_code: "909",
  });
  const [riders, setRiders] = useState([
    {
      first_name: "",
      last_name: "",
      user_gender: "",
      email: "",
      phone_number: "",
      profile_image: "",
    },
  ]);
  const [admins, setAdmins] = useState([
    {
      first_name: "",
      last_name: "",
      user_gender: "",
      email: "",
      phone_number: "",
    },
  ]);
  const [selectedlocations, setSelectedLocations] = useState([]);
  const [cokitchens, setCokitchens] = useState([]);
  const [logistics, setLogistics] = useState(null);
  const [logisticsCopy, setLogisticsCopy] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userDetailsloading, setUserDetailsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [promptType, setPromptType] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  // const valid = (current) => {
  //   return current.isAfter(moment(startDate));
  // };

  // load data from store
  useEffect(async () => {
    if (!homeStore.dataCached) {
      await loadData();
    }

    if (!paystackStore.dataCached) {
      paystackStore.getBanks(alert, false);
    }
    setPartners(homeStore?.logistics?.logistics_companies);
    setActivePartner(
      partnerStore && partnerStore.partners && partnerStore.partners[0]
    );
    setCokitchens(CommonStore.cokitchens);
  }, []);

  // Sort logistics riders  and admins
  useEffect(() => {
    let onlineRidersCopy = [],
      offlineRidersCopy = [],
      adminsCopy = [];
    if (activePartner?.users) {
      for (
        let i = 0,
          users = activePartner?.users,
          len = activePartner?.users.length;
        i < len;
        i++
      ) {
        if (users[i].role === "RIDER" && users[i].active) {
          onlineRidersCopy.push(users[i]);
        } else if (users[i].role === "RIDER" && !users[i].active) {
          offlineRidersCopy.push(users[i]);
        } else if (users[i].role === "LOGISTICS_ADMIN") {
          adminsCopy.push(users[i]);
        }
      }

      setRidersOnline(onlineRidersCopy);
      setRidersOffline(offlineRidersCopy);
      setCurrentAdmins(adminsCopy);
    }
  }, [activePartner]);

  // Get account details
  useEffect(async () => {
    if (
      logisticsCompany?.payout_bank_code &&
      logisticsCompany?.payout_account_number?.length > 9 &&
      !userDetailsloading
    ) {
      setUserDetailsLoading(true);
      let res = await paystackStore.getUserBankDetails(
        alert,
        logisticsCompany?.payout_account_number,
        logisticsCompany?.payout_bank_code
      );

      if (res?.account_name) {
        setLogisticsCompany({
          ...logisticsCompany,
          payout_account_name: res?.account_name,
        });
      } else {
        setLogisticsCompany({
          ...logisticsCompany,
          payout_account_name: "",
        });
      }
      setUserDetailsLoading(false);
    }
  }, [logisticsCompany.payout_account_number]);

  const loadData = async () => {
    setLoading(true);
    await Promise.all([
      //   partnerStore.getPartners(alert, true),
      CommonStore.getCokitchens(alert),
      homeStore.getLogistics(alert, false),
    ]);

    setLoading(false);

    setLogistics(homeStore.logistics);
    setLogisticsCopy(homeStore.logistics);
    setCokitchens(CommonStore.cokitchens);
    setLoading(false);
  };

  // Add new admins
  const addAdmin = () => {
    setAdmins((prev) => [
      ...prev,
      {
        first_name: "",
        last_name: "",
        user_gender: "",
        email: "",
        phone_number: "",
      },
    ]);
  };

  // Add new riders
  const addRider = () => {
    setRiders((prev) => [
      ...prev,
      {
        first_name: "",
        last_name: "",
        user_gender: "",
        email: "",
        phone_number: "",
      },
    ]);
  };

  //set default riders and admins when edit modal is displayed
  const setDefaultRidersandAdmins = () => {
    let ridersCopy = [],
      adminsCopy = [];
    if (activePartner?.users) {
      for (
        let i = 0,
          users = JSON.parse(JSON.stringify(activePartner?.users)),
          len = activePartner?.users.length;
        i < len;
        i++
      ) {
        if (users[i].role === "RIDER") {
          ridersCopy.push(users[i]);
        } else if (users[i].role === "LOGISTICS_ADMIN") {
          adminsCopy.push(users[i]);
        }
      }

      setRiders(ridersCopy);
      setAdmins(adminsCopy);
    }
  };

  // Set the select defaults for preselected locations served
  const setSelectDefaults = (items, isString) => {
    let options = [];
    let i;

    if (items && items.length && items.length > 0 && !isString) {
      for (i = 0; i < items.length; i++) {
        options.push({ label: items[i]?.cokitchen?.name, value: items[i].id });
      }
    } else if (items && items.length && items.length > 0 && isString) {
      for (i = 0; i < items.length; i++) {
        options.push({ value: items[i] });
      }
    }

    return options;
  };

  const handleDelete = (state, index, setState) => {
    const allFields = [...state];
    allFields.splice(index, 1);
    setState(allFields);
  };

  const handleChange = (state, index, field, e, setState) => {
    const allFields = [...state];
    allFields[index][field] = e;
    if (field === "profile_image") {
      allFields[index]["profile_display"] = URL.createObjectURL(e);
    }
    setState(allFields);
  };

  const formSubmit = async () => {
    const { postPartners, getPartners, partners } = partnerStore;
    let errorEncountered = { imageError: false, uploadError: false };
    setSubmitting(true);
    let ridersCopy = riders;
    let adminsCopy = JSON.parse(JSON.stringify(admins));
    for (let i = 0, len = ridersCopy.length; i < len; i++) {
      if (!ridersCopy[i].profile_image) {
        alert.show(`Please upload an image for rider ${i + 1}`, {
          type: "info",
        });
        errorEncountered.imageError = true;
      }
    }

    for (let i = 0, len = adminsCopy.length; i < len; i++) {
      adminsCopy[i].user_gender = adminsCopy[i].user_gender.toLowerCase();
      delete adminsCopy[i].username;
      delete adminsCopy[i].updated_at;
      delete adminsCopy[i].role;
      delete adminsCopy[i].logistics_company_id;
      delete adminsCopy[i].online;
      delete adminsCopy[i].lng;
      delete adminsCopy[i].lat;
      delete adminsCopy[i].created_at;
      delete adminsCopy[i].balance;
      delete adminsCopy[i].active;
      delete adminsCopy[i].profile_display;
      delete adminsCopy[i].profile_image;
      delete adminsCopy[i].email_confirmed;
      delete adminsCopy[i].other_name;
      delete adminsCopy[i].dob;
    }
    if (!errorEncountered.imageError) {
      for (let i = 0, len = ridersCopy.length; i < len; i++) {
        if (typeof ridersCopy[i].profile_image !== "string") {
          const formData = new FormData();
          const CLOUDINARY_CLOUDNAME =
            process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
          const CLOUDINARY_UPLOAD_PRESET =
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
          formData.append("file", ridersCopy[i].profile_image);
          formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
          const urlRes = await axios
            .post(
              `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
              formData
            )
            .catch((err) => {
              errorEncountered.uploadError = true;
              alert.show(
                err?.error?.message ||
                  "An error ocurred while uploading the image. Try again!",
                {
                  type: "error",
                }
              );
              setSubmitting(false);
              return;
            });
          if (urlRes) {
            ridersCopy[i].profile_image = urlRes?.data?.url;
            delete ridersCopy[i].profile_display;
          }
        }

        ridersCopy[i].user_gender = ridersCopy[i].user_gender.toLowerCase();
        delete ridersCopy[i].username;
        delete ridersCopy[i].updated_at;
        delete ridersCopy[i].role;
        delete ridersCopy[i].logistics_company_id;
        delete ridersCopy[i].online;
        delete ridersCopy[i].lng;
        delete ridersCopy[i].lat;
        delete ridersCopy[i].created_at;
        delete ridersCopy[i].balance;
        delete ridersCopy[i].active;
        delete ridersCopy[i].email_confirmed;
        delete ridersCopy[i].other_name;
        delete ridersCopy[i].dob;
      }
    }
    if (!errorEncountered.uploadError) {
      let body = JSON.parse(JSON.stringify(logisticsCompany));
      body.locations_served = selectedlocations;
      delete body.active;
      delete body.balance;
      delete body.updated_at;
      delete body.logo;
      delete body.last_payment;
      delete body.images;
      delete body.created_at;
      delete body.cancelled_trips;
      delete body.users;
      delete body.payout_transfer_recipient_code;
      promptType === "edit" && delete body.locations_served;

      await postPartners(
        alert,
        {
          logistics_company: body,
          riders: ridersCopy,
          logistics_admins: adminsCopy,
        },
        promptType === "edit"
      );

      setRiders(ridersCopy);
      setSubmitting(false);
      await homeStore.getLogistics(alert, false);
      setLogistics(homeStore.logistics);
      setLogisticsCopy(homeStore.logistics);
      setPartners(homeStore?.logistics?.logistics_companies);
      setShowModal(false);
    }

    setSubmitting(false);
  };

  const deletePartner = async () => {
    const { deleteLogisticsPartner } = partnerStore;
    setDeleting(true);
    await deleteLogisticsPartner(alert, activePartner?.id);
    setDeleting(false);
    setActivePartner("");
    setShowPrompt(false);

    await homeStore.getLogistics(alert, false);
    setLogistics(homeStore.logistics);
    setLogisticsCopy(homeStore.logistics);
    setPartners(homeStore?.logistics?.logistics_companies);
  };

  const paymentSubmit = async () => {
    const { updatePayment } = partnerStore;
    if (!activePartner) {
      alert.show("Please select a partner!", {
        type: "info",
      });
    } else if (!paymentForm?.amount) {
      alert.show("Please enter an amount to be paid!", {
        type: "info",
      });
    } else if (
      parseFloat(paymentForm?.amount).toFixed(1) >
      parseFloat(activePartner?.balance).toFixed(1)
    ) {
      alert.show("Amount can not be more than balance!", {
        type: "info",
      });
    } else {
      setPaymentLoading(true);
      let body = paymentForm;
      body.logistics_company_id = activePartner?.id;
      !body.note && delete body.note;
      await updatePayment(alert, body);
      setPaymentLoading(false);
      await homeStore.getLogistics(alert, false);
      setLogistics(homeStore.logistics);
      setLogisticsCopy(homeStore.logistics);
      setPartners(homeStore?.logistics?.logistics_companies);
    }
  };

  const resetModaForm = () => {
    setLogisticsCompany({
      contact_phone_number: "",
      name: "",
      address: "",
      contact_email: "",
      payout_bank_name: "",
      payout_account_name: "",
      payout_account_number: "",
      payout_bank_code: "909",
    });
    setAdmins([
      {
        first_name: "",
        last_name: "",
        user_gender: "",
        email: "",
        phone_number: "",
      },
    ]);

    setRiders([
      {
        first_name: "",
        last_name: "",
        user_gender: "",
        email: "",
        phone_number: "",
        profile_image: "",
      },
    ]);
    setPromptType("add");
    setShowModal(true);
  };

  let active = (activePartner && activePartner) || null;

  return (
    <Layout
      locationChange={() => setLocation()}
      location_set="active_nav_add"
      withHistoryBtn
    >
      <section className="main_section flex_row_top content_section tab_column mb_45">
        <section className="left_section pr_25 width_full">
          {/* Left */}
          <div className="flex_row_between mb_35">
            <h6 className="bold_24px mr_10">
              Logistic partners ({loading ? "---" : partners && partners.length}
              )
            </h6>
            <div
              className="flex_row_right cursor_pointer"
              onClick={() => {
                resetModaForm();
              }}
            >
              <div className="flex_row_center btn_span bg_dark">
                <HiPlus fill="#B9B9B9" width="14px" height="14px" />
              </div>
              <label className="normal_18px p_20 bold">Add new partner</label>
            </div>
          </div>

          {partners &&
            partners.map((item, i) => {
              return (
                <Card
                  key={i + "card"}
                  onClick={() => setActivePartner(item)}
                  name={item.name}
                  active={activePartner && activePartner.name === item.name}
                  riders={item && item.riders && item.riders.length}
                  age={item.age}
                  trips={item.trips}
                  ridersOnline={item.ridersOnline}
                  lastPayout={item.lastPayout}
                  loading={loading}
                />
              );
            })}
        </section>

        <section className="right_section p_20 width_full flex_column_start">
          {/* Right */}

          <p className="bold_21px mb_25">{active?.name} </p>
          <div className="flex_row bold_21px mb_30">
            Riders ({ridersOnline?.length + ridersOffline?.length}) -{" "}
            <span className="bold_21px green_text">
              {" " + ridersOnline?.length + " " || " " + 0 + " "} online
            </span>
          </div>

          <div className="flex_column_start mb_35">
            {ridersOnline &&
              ridersOnline.map((item, i) => {
                return (
                  <div
                    key={i + item.name + i}
                    className="flex_row mb_15 bold_18_px"
                  >
                    {i + 1}. {item.first_name + " " + item.last_name} (
                    {item.phone_number}) - {item.email}
                  </div>
                );
              })}
            {ridersOffline &&
              ridersOffline.map((item, i) => {
                return (
                  <div
                    key={i + item.name + i}
                    className="flex_row mb_15 normal_18px"
                  >
                    {ridersOnline.length + i + 1}.{" "}
                    {item.first_name + " " + item.last_name} (
                    {item.phone_number}) - {item.email}
                  </div>
                );
              })}
          </div>
          {/* One */}
          <div className="flex_row_between width_full tab_column mb_35">
            <div className="flex_column_start">
              <h6 className="bold_18_px mb_15">
                Contact person(s) (Admin(s)):
              </h6>
              {currentAdmins &&
                currentAdmins.map((item, i) => {
                  return (
                    <div key={item.id}>
                      <p className="normal_18px mb_12">
                        {i + 1}
                        {"."} {item?.first_name + " " + item.last_name}
                      </p>
                      <p className="normal_18px mb_12">{item.phone_number}</p>
                      <p className="normal_18px mb_12">{item.email}</p>
                    </div>
                  );
                })}
            </div>
            <div className="flex_column_end">
              <h6 className="bold_18_px mb_15">Company address:</h6>
              <p className="normal_18px mb_12">{active?.address}</p>
            </div>
          </div>
          {/* Two */}
          <div className="flex_row_between width_full tab_column mb_45">
            <div className="flex_column_start">
              <h6 className="bold_18_px mb_15">Payout details:</h6>
              <p className="normal_18px mb_12">{active?.payout_bank_name}</p>
              <p className="normal_18px mb_12">{active?.payout_account_name}</p>
              <p className="normal_18px mb_12">
                {active?.payout_account_number}
              </p>
            </div>
            <div className="flex_column_end">
              <h6 className="bold_18_px mb_15">
                Locations served ({active?.locations_served.length || 0}):
              </h6>
              {active?.locations_served.map((item, i) => {
                return (
                  <p className="normal_18px mb_12">
                    {i + 1}. {item?.cokitchen?.name}
                  </p>
                );
              })}
            </div>
          </div>
          {/* End */}
          <div className="flex_row_between width_full tab_column mb_25">
            <div className="flex_column_start">
              <h6 className="bold_18_px mb_12">Amount due for next payout:</h6>
              <span className="normal_53px bold green_text mb_15">
                ₦{active?.balance && numberWithCommas(active?.balance || "0")}
              </span>

              <p className="normal_15px ">
                Last payment date: {active?.last_payment?.created_at}
              </p>
            </div>
            <form
              className="flex_column_end"
              onSubmit={(e) => {
                e.preventDefault();
                paymentSubmit();
              }}
            >
              <Input
                containerClass="mb_15"
                placeholder="Amount paid"
                required
                value={paymentForm.amount}
                onChange={(e) => {
                  setPaymentForm({ ...paymentForm, amount: e });
                }}
                max={active?.balance}
              />
              <Input
                containerClass="mb_15"
                placeholder="Type any notes here (Optional)"
                value={paymentForm.note}
                onChange={(e) => {
                  setPaymentForm({ ...paymentForm, note: e });
                }}
              />
              <IconButton
                text={paymentLoading ? "Paying..." : "Pay delivery partner"}
                type="submit"
                loading={paymentLoading}
                onClick={() => paymentSubmit()}
              />
            </form>
          </div>
          {/* End */}
          <div className="flex_row_between width_full tab_column mb_25">
            <div className="flex_column_start">
              <h6 className="normal_15px mb_12 red_text">
                {active?.cancelled_trips.length} cancelled
                {active?.cancelled_trips.length === 1 ? " trip" : " trips"}
              </h6>

              <p className="normal_15px bold ">
                Cancellation note:
                <br />
                {active && active.cancelled && active.cancelled.note}
              </p>
            </div>
          </div>
          <div className="flex_row_between width_full">
            <IconButton
              text="Edit delivery partner"
              onClick={() => {
                if (active) {
                  setLogisticsCompany(active);
                  setDefaultRidersandAdmins();
                  setPromptType("edit");
                  setShowModal(true);
                } else {
                  alert.show("Please select a partner to be edited", {
                    type: "info",
                  });
                }
              }}
            />
            <IconButton
              text="Delete delivery partner"
              isRedThick
              onClick={() => {
                if (active) {
                  setShowPrompt(true);
                } else {
                  alert.show("Please select a partner to be deleted", {
                    type: "info",
                  });
                }
              }}
            />
          </div>

          {/* End */}
        </section>

        <Modal
          showModal={showModal}
          closeModal={() => setShowModal(!showModal)}
          title={
            promptType === "add"
              ? "Add new delivery partner"
              : "Edit " + active?.name
          }
          onSubmit={(e) => {
            e.preventDefault();
            formSubmit();
          }}
          saveText={
            submitting && promptType === "add"
              ? "Creating..."
              : !submitting && promptType === "add"
              ? "Create Partner"
              : submitting && promptType === "edit"
              ? "Updating..."
              : "Update Partner"
          }
          submitting={submitting}
          content={
            <>
              <IconInput
                required
                withLabel
                label="Registered company name: "
                placeholder="Company name"
                bold
                type="text"
                containerClass="mb_15 width_full"
                value={logisticsCompany.name}
                onChange={(e) =>
                  setLogisticsCompany({ ...logisticsCompany, name: e })
                }
              />

              <IconInput
                required
                withLabel
                label="Company address"
                placeholder="Company address"
                bold
                type="text"
                containerClass="mb_15 width_full"
                value={logisticsCompany.address}
                onChange={(e) =>
                  setLogisticsCompany({ ...logisticsCompany, address: e })
                }
              />
              <IconInput
                required
                withLabel
                label="Company phone"
                placeholder="Company phone"
                bold
                type="text"
                containerClass="mb_15 width_full"
                value={logisticsCompany.contact_phone_number}
                onChange={(e) =>
                  setLogisticsCompany({
                    ...logisticsCompany,
                    contact_phone_number: e,
                  })
                }
              />

              <IconInput
                required
                withLabel
                label="Company email"
                placeholder="Company email"
                bold
                type="text"
                containerClass="mb_15 width_full"
                value={logisticsCompany.contact_email}
                onChange={(e) =>
                  setLogisticsCompany({
                    ...logisticsCompany,
                    contact_email: e,
                  })
                }
              />
              <label className={`mb_15 normal_17px bold`}>
                Locations served
              </label>
              <Select
                placeholder="CoKitchen location served"
                defaultValue={
                  (promptType === "edit" &&
                    setSelectDefaults(active?.locations_served, false)) ||
                  []
                }
                containerClass="width_full mb_45"
                options={cokitchens}
                isMulti
                handleChange={(e) => {
                  let i;
                  let copy = [];
                  for (i = 0; i < e.length; i++) {
                    copy.push(e[i].id);
                  }
                  setSelectedLocations(copy);
                }}
              />
              <label className={`mb_15 normal_17px bold`}>Payout details</label>
              <div className="flex_row_between tab_column width_full">
                {/* <IconInput
                  required
                  placeholder="Bank name"
                  bold
                  type="text"
                  containerClass="width_full left_input "
                  value={logisticsCompany.payout_bank_name}
                  onChange={(e) =>
                    setLogisticsCompany({
                      ...logisticsCompany,
                      payout_bank_name: e,
                    })
                  }
                /> */}

                <Select
                  placeholder="Select bank"
                  defaultValue={
                    (promptType === "edit" &&
                      paystackStore.banks?.find(
                        (item) => item.name === active?.payout_bank_name
                      )) ||
                    ""
                  }
                  containerClass="width_full mb_15"
                  options={paystackStore.banks}
                  handleChange={(e) => {
                    setLogisticsCompany({
                      ...logisticsCompany,
                      payout_bank_name: e.name,
                      payout_bank_code: e.code,
                    });
                  }}
                  name="name"
                  id="code"
                />
                <IconInput
                  required
                  placeholder="Account number"
                  bold
                  type="text"
                  containerClass="width_full right_input"
                  value={logisticsCompany.payout_account_number}
                  onChange={(e) =>
                    setLogisticsCompany({
                      ...logisticsCompany,
                      payout_account_number: e,
                    })
                  }
                  disabled={userDetailsloading}
                />
              </div>
              <div className="flex_row_between tab_column width_full">
                <IconInput
                  required
                  placeholder="Account name"
                  bold
                  type="text"
                  disabled
                  containerClass="mb_15 width_full"
                  value={logisticsCompany.payout_account_name}
                  onChange={(e) =>
                    setLogisticsCompany({
                      ...logisticsCompany,
                      payout_account_name: e,
                    })
                  }
                  loading={userDetailsloading}
                />
              </div>

              <div className="flex_row_between tab_column width_full mb_35">
                <label className={`normal_17px bold`}>Admin details</label>
                <IconButton
                  text="Add additional admin"
                  className=""
                  onClick={addAdmin}
                  type="button"
                />
              </div>
              {admins.map((item, i) => {
                return (
                  <div className="width_full">
                    <div className="flex_row_between tab_column width_full">
                      <p className="normal_15px mb_5">Admin number {i + 1}</p>

                      {promptType === "add" ? (
                        <IoMdCloseCircleOutline
                          fill="#B9B9B9"
                          width="14px"
                          height="14px"
                          onClick={() => {
                            handleDelete(admins, i, setAdmins);
                          }}
                          className="cursor_pointer"
                        />
                      ) : null}
                    </div>
                    <div
                      className="flex_row_between tab_column width_full"
                      key={i + "admin" + i}
                    >
                      <IconInput
                        required
                        placeholder="Admin first name"
                        type="text"
                        containerClass="width_full left_input"
                        value={item.first_name}
                        onChange={(e) => {
                          handleChange(admins, i, "first_name", e, setAdmins);
                        }}
                      />
                      <IconInput
                        required
                        placeholder="Admin last name"
                        type="text"
                        containerClass="width_full right_input"
                        value={item.last_name}
                        onChange={(e) => {
                          handleChange(admins, i, "last_name", e, setAdmins);
                        }}
                      />
                    </div>
                    <div className="flex_row_between tab_column width_full">
                      <IconInput
                        required
                        placeholder="Admin email"
                        type="email"
                        containerClass="mb_15 width_full"
                        value={item.email}
                        onChange={(e) => {
                          handleChange(admins, i, "email", e, setAdmins);
                        }}
                      />
                      <IconInput
                        required
                        placeholder="Admin phone"
                        type="text"
                        containerClass="width_full right_input"
                        value={item.phone_number}
                        onChange={(e) => {
                          handleChange(admins, i, "phone_number", e, setAdmins);
                        }}
                      />
                    </div>
                    <div className="flex_row_between tab_column width_full">
                      <Select
                        placeholder="Admin gender"
                        defaultValue={
                          (promptType === "edit" && {
                            label: admins[i]?.user_gender,
                            value: admins[i]?.user_gender,
                          }) ||
                          ""
                        }
                        containerClass="width_full mb_15"
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        handleChange={(e) => {
                          handleChange(
                            admins,
                            i,
                            "user_gender",
                            e.value,
                            setAdmins
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}

              {/* Bikers */}

              <div className="flex_row_between tab_column width_full mb_35">
                <label className={`normal_17px bold`}>
                  Rider/ bike details
                </label>
                <IconButton
                  text="Add additional rider"
                  className=""
                  onClick={addRider}
                  type="button"
                />
              </div>
              {riders.map((item, i) => {
                return (
                  <div className="width_full">
                    <div className="flex_row_between tab_column width_full">
                      <p className="normal_15px mb_5">Rider number {i + 1}</p>
                      {promptType === "add" ? (
                        <IoMdCloseCircleOutline
                          fill="#B9B9B9"
                          width="14px"
                          height="14px"
                          onClick={() => {
                            handleDelete(riders, i, setRiders);
                          }}
                          className="cursor_pointer"
                        />
                      ) : null}
                    </div>
                    <div
                      className="flex_row_between tab_column width_full"
                      key={i + "rider" + i}
                    >
                      <IconInput
                        required
                        placeholder="Rider first name"
                        type="text"
                        containerClass="width_full left_input"
                        value={item.first_name}
                        onChange={(e) => {
                          handleChange(riders, i, "first_name", e, setRiders);
                        }}
                      />
                      <IconInput
                        required
                        placeholder="Rider last name"
                        type="text"
                        containerClass="width_full right_input"
                        value={item.last_name}
                        onChange={(e) => {
                          handleChange(riders, i, "last_name", e, setRiders);
                        }}
                      />
                    </div>
                    <div className="flex_row_between tab_column width_full">
                      <IconInput
                        required
                        placeholder="Rider email"
                        type="email"
                        containerClass="mb_15 width_full"
                        value={item.email}
                        onChange={(e) => {
                          handleChange(riders, i, "email", e, setRiders);
                        }}
                      />
                      <IconInput
                        required
                        placeholder="Rider phone"
                        type="text"
                        containerClass="width_full right_input"
                        value={item.phone_number}
                        onChange={(e) => {
                          handleChange(riders, i, "phone_number", e, setRiders);
                        }}
                      />
                    </div>
                    <div className="flex_row_between tab_column width_full">
                      <Select
                        placeholder="Rider gender"
                        defaultValue={
                          (promptType === "edit" && {
                            label: riders[i]?.user_gender,
                            value: riders[i]?.user_gender,
                          }) ||
                          ""
                        }
                        containerClass="width_full mb_15"
                        options={[
                          { label: "Male", value: "Male" },
                          { label: "Female", value: "Female" },
                        ]}
                        handleChange={(e) => {
                          handleChange(
                            riders,
                            i,
                            "user_gender",
                            e.value,
                            setRiders
                          );
                        }}
                      />

                      <ImageFile
                        className="ml_10"
                        onChange={(e) => {
                          handleChange(
                            riders,
                            i,
                            "profile_image",
                            e.target.files[0],
                            setRiders
                          );
                        }}
                        src={item?.profile_display || item?.profile_image}
                        text="Upload Image"
                      />
                    </div>
                  </div>
                );
              })}
            </>
          }
        />
        <Prompt
          show={showPrompt}
          closeModal={() => setShowPrompt(!showPrompt)}
          title={`Are you sure you want to delete ${active?.name} ?`}
          onSubmit={(e) => {
            e.preventDefault();
            deletePartner();
          }}
          saveText={deleting ? "Deleting partner..." : "Delete"}
          loading={deleting}
          submitting={deleting}
          withGreen
          withRed
        />
      </section>
    </Layout>
  );
};

export default withAlert()(PartnerDB);
